import React, { Component } from "react";
import CustomCard from "../../components/common/CustomCard";
import CustomChartView from "../../components/common/CustomChartView";
import DashboardCalendarEventLists from "./DashboardCalendarEventLists";
import DashboardSchoolSection from "./DashboardSchoolSection";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import DashboardGroupMeet from "./DashboardGroupMeet";
import DashboardDiscussionBoard from "./DashboardDiscussionBoard";
import ModalWindow from "../../UI/ModalWindow";
import CentralVideoConference from "../VideoConferencing/CentralVideoConference";

class DashboardPage extends Component {
  state = {
    labels: [
      {
        name: "Total Schools",
        value: "",
        changeText: "",
        materialIcon: "school",
      },
      {
        name: "Total Teacher",
        value: "",
        changeText: "",
        maleTotal:"",
        femaleTotal:"",
        materialIcon: "person",
      },
      {
        name: "Total Students",
        value: "",
        changeText: "",
        maleTotal:"",
        femaleTotal:"",
        materialIcon: "groups",
      },
      {
        name: "Total Parents",
        value: "",
        changeText: "",
        materialIcon: "group",
      },
    ],
    loginStatistics: [
      {
        name: "Admin Login",
        value: "",
        changeText: "",
        subtitle: "Today",
        materialIcon: "schoollogin",
      },
      {
        name: "User Login",
        value: "",
        changeText: "",
        subtitle: "Today",
        materialIcon: "schoollogin",
      },
      {
        name: "Teacher Login",
        value: "",
        changeText: "",
        subtitle: "Today",
        materialIcon: "personlogin",
      },
      {
        name: "Student Login",
        value: "",
        changeText: "",
        subtitle: "Today",
        materialIcon: "groupslogin",
      },
      {
        name: "Parents Login",
        value: "",
        changeText: "",
        subtitle: "Today",
        materialIcon: "grouplogin",
      },
    ],
    downloadMaterials: {
      name: "Downloaded Materials",
      value: "--",
      changeText: "",

      materialIcon: "download",
    },
    calendarData: [],
    calendarSpinner: false,
    chartData: "",
    currentRecordsLength: 0,
    fetchingGroupMembers: false,
    groups: [],
    groupId: "",
    groupDiscussions: [],
    groupMembers: [],
    meetingModal: false,
    schoolChartData: null,
    schools: [],
    selectedSchool: null,
    selectedSchoolId: "all",
    totalGroupDiscussions: 0,
    totalMembers: null,
    viewerData: "",
  };

  componentDidMount() {
    this.getCalendarEvents();
    this.getAllSchoolSummary();
    this.getAllSchools();
    this.getUserLoginStatistics();
    this.getProvinces();
    this.getAllGroups();
    this.getDiscussionsByGroup();
    this.getViewerStat();
    // this.getSchoolDataByMonth();
  }

  getAllGroups = () => {
    axiosPost(URL.getAllGroupsCentral, {}, (response) => {
      if (response.status === 200) {
        let groups = response.data.data;
        if (groups && groups.length > 0) {
          this.setState({
            groups,
            groupId: groups[0].id,
          });
          this.getGroupMembers(groups[0].id);
        }
      }
    });
  };

  getAllSchoolChartData = () => {
    let datum = this.state.labels;
    let temp = {};
    datum.forEach((el) => {
      if (el.name === "Total Teacher") {
        temp.staffCount = el.value;
        el.subCategory = `Male: ${
          datum ? el?.maleTotal : 0
        } | Female: ${datum ? el?.femaleTotal : 0}`;
      }
      if (el.name === "Total Students") {
        temp.studentCount = el.value;
        el.subCategory = `Male: ${
          datum ? el?.maleTotal : 0
        } | Female: ${datum ? el?.femaleTotal : 0}`;
      }
      if (el.name === "Total Parents") {
        temp.parentCount = el.value;
      }
    });
    this.setState({ schoolChartData: temp });
  };

  getAllSchools = () => {
    axiosPost(URL.getAllSchools, {}, (response) => {
      if (response.status === 200) {
        let labels = this.state.labels;
        labels[0].value = response.data.data.length;
        if (response.data.data.length > 0) {
          // this.setState(
          //   {
          //     selectedSchool: response.data.data[0],
          //     selectedSchoolId: response.data.data[0].id,
          //   },
          //   function () {
          //     this.getSchoolChartData();
          //   }
          // );
        }
        this.setState({ schools: response.data.data, labels });
      }
    });
  };

  getAllSchoolSummary = () => {
    axiosGet(URL.getAllSchoolSummary, (response) => {
      if (response.status === 200) {
        let labels = this.state.labels;
        let datum = response.data.data;
        labels.forEach((el) => {
          if (el.name === "Total Schools") {
            el.value = datum ? datum.schoolCount : 0;
          }
          if (el.name === "Total Teacher") {
            el.value = datum ? datum.currentStaffCount : 0;
            el.maleTotal = datum ? datum.staffMaleCount : 0;
            el.femaleTotal = datum ? datum.staffFemaleCount : 0;
          }
          if (el.name === "Total Students") {
            el.value = datum ? datum.currentStudentCount : 0;
            el.maleTotal = datum ? datum.maleStudentCount : 0;
            el.femaleTotal = datum ? datum.femaleStudentCount : 0;
          }
          if (el.name === "Total Parents") {
            el.value = datum ? datum.studentContactCount : 0;
          }
        });
        this.setState({ labels }, function () {
          this.getAllSchoolChartData();
        });
      }
    });
  };

  getSchoolChartData = () => {
    let datum = this.state.selectedSchool;
    let param = {
      id: datum.id,
    };
    axiosPost(URL.getSchoolChartData, param, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolChartData: response.data.data,
        });
      }
    });
  };

  getUserLoginStatistics = () => {
    axiosPost(URL.getTodayLoginStatistics, {}, (response) => {
      if (response.status === 200) {
        let loginStatistics = this.state.loginStatistics;
        let datum = response.data.data;

        loginStatistics.forEach((el) => {
          if (el.name === "Teacher Login") {
            el.value = datum ? datum.staffLogin : 0;
            el.value += datum ? datum.staffInstitutionalLogin : 0;
            el.changeText = `Individual: ${
              datum ? datum.staffLogin : 0
            } | Institution: ${datum ? datum.staffInstitutionalLogin : 0}`;
          }
          if (el.name === "Student Login") {
            el.value = datum ? datum.studentLogin : 0;
            el.value += datum ? datum.studentInstitutionalLogin : 0;
            el.changeText = `Individual: ${
              datum ? datum.studentLogin : 0
            } | Institution: ${datum ? datum.studentInstitutionalLogin : 0}`;
          }
          if (el.name === "Parents Login") {
            el.value = datum ? datum.parentsLogin : 0;
            el.value += datum ? datum.parentsInstitutionalLogin : 0;
            el.changeText = `Individual: ${
              datum ? datum.parentsLogin : 0
            } | Institution: ${datum ? datum.parentsInstitutionalLogin : 0}`;
          }
          if (el.name === "User Login") {
            el.value = datum
              ? datum.parentsLogin +
                datum.studentLogin +
                datum.staffLogin +
                datum.adminLogin +
                datum.parentsInstitutionalLogin +
                datum.staffInstitutionalLogin +
                datum.studentInstitutionalLogin
              : 0;
            // el.value += 4 + 8 + 5 + 2;
            let othersLogin = el.value - datum.adminLogin;

            el.changeText = `Others: ${othersLogin} | Admin: ${
              datum ? datum.adminLogin : 0
            } `;
            el.subtitle = `Unauthorized Attempts: ${
              datum ? datum.unauthorizedLogin : 0
            }  `;
          }
          if (el.name === "Admin Login") {
            el.value = datum ? datum.centralAdminLogin : 0;
            el.value += datum ? datum.nationalAdminLogin : 0;
            el.changeText = `National: ${datum?.nationalAdminLogin} | Central: ${datum.centralAdminLogin}`;
          }
        });

        //download count set
        let downloadMaterials = { ...this.state.downloadMaterials };
        downloadMaterials.value = datum.downloadCount;
        this.setState({ loginStatistics, downloadMaterials });
      }
    });
  };
  getCalendarEvents = () => {
    let param = {
      start: new Date().toISOString(),
      offset: 0,
      rowCountLimit: 5,
    };
    this.setState({ calendarSpinner: true }, function () {
      axiosPost(
        URL.searchCalendarEventCore,
        param,
        (response) => {
          this.setState({ calendarSpinner: false });
          if (response.status === 200) {
            this.setState({
              calendarData: response.data.data,
            });
          }
        },
        (err) => {
          this.setState({ calendarSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  getGroupMembers = (groupId) => {
    this.setState({ fetchingGroupMembers: true, groupMembers: [] }, () => {
      let data = {
        id: groupId,
      };
      axiosPost(
        URL.getGroupSelectByKey,
        data,
        (response) => {
          if (response.status === 200) {
            let totalMembers = response.data.totalRecordsCount;
            let groupMembers = response.data.data?.groupPeople;
            this.setState({
              groupMembers,
              totalMembers,
              fetchingGroupMembers: false,
            });
          } else {
            this.setState({
              fetchingGroupMembers: false,
            });
          }
        },
        (error) => {
          this.setState({
            fetchingGroupMembers: false,
          });
        }
      );
    });
  };

  getDiscussionsByGroup = () => {
    this.setState({ postSpinner: true }, () => {
      const param = {
        postType: postType.discussion,
        limitOne: true,
      };

      axiosPost(
        URL.selectGroupPost,
        param,
        (response) => {
          if (response.status === 200) {
            let groupDiscussions = response.data.data;
            let totalGroupDiscussions = response.data.totalRecordsCount;
            let currentRecordsLength = groupDiscussions.length;
            this.setState({
              groupDiscussions,
              totalGroupDiscussions,
              currentRecordsLength,
              postSpinner: false,
            });
          } else {
            this.setState({
              postSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({
            postSpinner: false,
          });
        }
      );
    });
  };

  getSchoolDataByMonth = () => {
    axiosPost(URL.getSchoolDataByMonth, {}, (response) => {
      if (response.status === 200) {
        this.setState({ chartData: response.data.data });
      }
    });
  };
  getProvinces = () => {
    const Params = {
      countryId: 147,
    };
    axiosPost(URL.getCountryFirstLevel, Params, (response) => {
      if (response.status === 200) {
        this.setState({ chartData: response.data.data });
      }
    });
  };

  getViewerStat = () => {
    axiosPost(URL.getViewerStat, {}, (response) => {
      if (response.status === 200) {
        this.setState({ viewerData: response.data.data });
      }
    });
  };

  handleChange = (e) => {
    let value = e.target.value;
    this.setState({ selectedSchool: null, schoolChartData: null }, function () {
      let schools = this.state.schools;
      if (value !== "all") {
        let selectedSchool = schools.find((el) => el.id === parseInt(value));
        this.setState(
          {
            selectedSchool: selectedSchool,
            selectedSchoolId: value,
          },
          function () {
            this.getSchoolChartData();
          }
        );
      } else {
        this.setState({ selectedSchoolId: value }, function () {
          this.getAllSchoolChartData();
        });
      }
    });
  };

  handleGroupDiscussionChange = (e) => {
    const { name, value } = e.target;
    if (name === "groupId") {
      this.getGroupMembers(value);
    }
    this.setState({ [name]: value });
  };

  handleMeeting = () => {
    if (this.state.groupId && this.state.groupId !== "")
      this.setState({ meetingModal: true });
  };

  toggleMeetingModal = () => {
    this.setState({
      meetingModal: !this.state.meetingModal,
    });
  };

  render() {
    return (
      <div className="container-fluid mt-4 dashboard-page-central">
        <div className="row">
          {this.state.labels.map((label, idx) => {
            return (
              <div className="col-md-3 col-sm-6 dashboard-card-50" key={idx}>
                <CustomCard label={label} index={idx}></CustomCard>
              </div>
            );
          })}
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          {this.state.loginStatistics
            .filter((st) => st.name !== "User Login")
            .map((loginStat, idx) => {
              return (
                <div className="col-md-3 col-sm-6 dashboard-card-50" key={idx}>
                  <CustomCard label={loginStat} index={idx}></CustomCard>
                </div>
              );
            })}
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <DashboardGroupMeet
                  fetchingGroupMembers={this.state.fetchingGroupMembers}
                  groupId={this.state.groupId}
                  groups={this.state.groups}
                  groupMembers={this.state.groupMembers}
                  handleGroupDiscussionChange={this.handleGroupDiscussionChange}
                  handleMeeting={this.handleMeeting}
                  postSpinner={this.state.postSpinner}
                />
              </div>
              <div className="col-md-6">
                <DashboardDiscussionBoard
                  groupDiscussions={this.state.groupDiscussions}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "1rem" }}>
              <div className="col-md-6">
                <div>
                  {this.state.chartData && this.state.chartData.length > 0 ? (
                    <CustomChartView
                      chartData={this.state.chartData}
                      title="Schools using System"
                    />
                  ) : null}
                </div>
                <div>
                  {this.state.loginStatistics
                    .filter((st) => st.name === "User Login")
                    .map((loginStat, idx) => {
                      return (
                        <div key={idx}>
                          <CustomCard
                            label={loginStat}
                            index={idx}
                          ></CustomCard>
                        </div>
                      );
                    })}
                </div>
                <div className="">
                  <CustomCard
                    label={this.state.downloadMaterials}
                    index={0}
                  ></CustomCard>
                </div>
              </div>

              <div className="col-md-6">
                <div>
                  {this.state.viewerData && this.state.viewerData.length > 0 ? (
                    <CustomChartView
                      chartData={this.state.viewerData}
                      title="Views Summary"
                      summary={true}
                    />
                  ) : null}
                </div>
                <div className="mb-4">
                  <DashboardCalendarEventLists
                    calendarData={this.state.calendarData}
                    calendarSpinner={this.state.calendarSpinner}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <DashboardSchoolSection
              handleChange={this.handleChange}
              schools={this.state.schools}
              selectedSchool={this.state.selectedSchool}
              schoolChartData={this.state.schoolChartData}
              selectedSchoolId={this.state.selectedSchoolId}
            />
          </div>
        </div>
        {/* <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-md-4">
            <DashboardGroupMeet
              fetchingGroupMembers={this.state.fetchingGroupMembers}
              groupId={this.state.groupId}
              groups={this.state.groups}
              groupMembers={this.state.groupMembers}
              handleGroupDiscussionChange={this.handleGroupDiscussionChange}
              handleMeeting={this.handleMeeting}
              postSpinner={this.state.postSpinner}
            />
          </div>
          <div className="col-md-4">
            <DashboardDiscussionBoard
              groupDiscussions={this.state.groupDiscussions}
            />
          </div>
          <div className="col-md-4">
            <DashboardSchoolSection
              handleChange={this.handleChange}
              schools={this.state.schools}
              selectedSchool={this.state.selectedSchool}
              schoolChartData={this.state.schoolChartData}
              selectedSchoolId={this.state.selectedSchoolId}
            />
          </div>
        </div> */}
        {/* <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-md-4">
            <div>
              {this.state.chartData && this.state.chartData.length > 0 ? (
                <CustomChartView
                  chartData={this.state.chartData}
                  title="Schools using Imagine Sys"
                />
              ) : null}
            </div>
            <div>
              {this.state.loginStatistics
                .filter((st) => st.name === "User Login")
                .map((loginStat, idx) => {
                  return (
                    <div key={idx}>
                      <CustomCard label={loginStat} index={idx}></CustomCard>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-md-4">
            <DashboardCalendarEventLists
              calendarData={this.state.calendarData}
              calendarSpinner={this.state.calendarSpinner}
            />
          </div>
        </div> */}

        <ModalWindow
          modal={this.state.meetingModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={this.toggleMeetingModal}
          modalHeader={"Meeting"}
          modalBody={
            <CentralVideoConference
              groupId={this.state.groupId}
              toggleMeetingModal={this.toggleMeetingModal}
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

export default DashboardPage;
