import React, { Component } from "react";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { valHooks } from "jquery";
import moment from "moment";

class ListStudentAttendanceDetails extends Component {
  state = {
    attendanceRecord: [],
    schoolInfos: null,
  };

  getStudeentName = () => {
    //fint the name of the std. whose attendance details is being downloaded
  };
  setTableHeader = (data) => {
let endOfMonth = moment(this.props.selectedMonth,'MMMM');
    return (data ?
      <>
        <th></th>
        {Array.from({ length: endOfMonth.daysInMonth() }, (_, i) => (
              <th key={i}>{i + 1}</th>
            ))}
        {/* {data.map((item, id) => {
          return (<th>{new Date(item.date).getDate()}</th>)
        })} */}
         <th>Total Present</th>
      </>
      : "")
  }

  renderAttendanceStatus = (key) => {
    let totalPresentDay = 0;
    this.props.studentAttendanceRecord[key].map(item => {
      if (item.isPresent)
        totalPresentDay++
    })
    let endOfMonth = moment(this.props.selectedMonth,'MMMM');
    return (
      <>
        {
          Array.from({ length: endOfMonth.daysInMonth() }, (_, i) => {
            let record = this.props.studentAttendanceRecord[key].find((item, id) =>
              new Date(item.date).getDate() == i + 1
            )
           return record ?
              <td>
                {record.isPresent ? (
                  <FontAwesomeIcon icon={faCheckCircle} color="#1abc9c" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} color="#a70707" />
                )}
              </td> : <td>-</td>
          })
        }
        <td>{totalPresentDay}</td>
      </>
    )
  };

  handleExportToPDF = async () => {
    document.getElementById("printAttendance").parentNode.style.overflow =
      "visible";
    const element = document.getElementById("printAttendance");
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.setFontSize(40);
    pdf.text(30, 20, "Hello world!");
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("AttendanceDetails.pdf");
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data,
        });
      }
    });
  };

  componentDidMount() {
    this.getSchoolInfo();
  }

  render() {
    return (
      <>

          <div>
            <div id="printAttendance">
              {Object.keys(this.props.studentAttendanceRecord).length > 0 && (
                <>
                  <div className="container-fluid row calendar-print-header my-3">
                    <div className="col-md-4">
                      <img
                        src={
                          URL.imageSelectURL + this.state.schoolInfos?.imageName
                        }
                        className="img-fluid"
                      />
                    </div>

                    <div className="col-md-4 text-center">
                      <h2>{this.state.schoolInfos?.name}</h2>
                      <h5>{this.state.schoolInfos?.address}</h5>
                      <h6>{this.state.currentAcYrName}</h6>
                    </div>
                    <div className="col-md-4"></div>
                  </div>

                  <div className="py-2">
                    <b>Month: </b>
                    {this.props.selectedMonth}
                  </div>
                </>
              )}
              <table className="table table-bordered text-center">
                <thead className="tt-group-header">
                  {this.setTableHeader(Object.values(this.props.studentAttendanceRecord)[0])}
                </thead>
                <tbody>
                  {Object.keys(this.props.studentAttendanceRecord).length >
                  0 ? (
                    <>
                      {Object.keys(this.props.studentAttendanceRecord).map(
                        (key, id) => {
                          return (
                            <tr>
                              <td>{key}</td>
                              {this.renderAttendanceStatus(key)}
                            </tr>
                          );
                        }
                      )}
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>
            <div className="text-right">
              <button
                className="tt-button tt-button-primary my-2"
                onClick={() => this.handleExportToPDF()}
                style={{
                  display: this.props.showBtn ? " block" : "none",
                }}
              >
                Export as PDF
              </button>
            </div>
          </div>
      </>
    );
  }
}
export default ListStudentAttendanceDetails;
