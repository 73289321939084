/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, SubjectAssessment, renders the List of Subject Assessments with Each Individual
 *              student enrolled in specific assessment
 */

import React, { Component } from "react";
import SubjectAssessmentCollapsible from "../../../UI/SubjectAssessmentCollapsible";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL, checkWidgetAdminOrNot } from "../../../../utils/Constants";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { Spinner } from "reactstrap";
import SubjectAssesmentRevampCollapsible from "./SubjectAssesmentRevampCollapsible";

class SubjectAssessment extends Component {
  state = {
    yearLevels: [],
    showSubjectAssessmentSpinner: true,
  };
  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches all the
   * Assessmetn Lists from the database.
   *
   */
  componentDidMount() {
    if (checkWidgetAdminOrNot(this.props.name) === true) {
      let param = {
        assessmentModuleCode: this.props.code,
      };
      axiosPost(
        URL.getYearLevelByAssessmentSubjectConfig,
        param,
        (response) => {
          if (response.status === 200) {
            const yearLevels = response.data.data;
            if (yearLevels.length > 0) {
              this.setState({ showSubjectAssessmentSpinner: true });
            } else {
              this.setState({ showSubjectAssessmentSpinner: false });
            }
            this.setState(
              {
                yearLevels,
              },
              () => {
                let yearLevels = [...this.state.yearLevels];
                yearLevels.forEach((level) => {
                  level.isYearLevelOpen = false;
                });
                this.setState({ yearLevels });
              }
            );
          }
        }
      );
    } else {
      this.getYearLevel();
    }
  }

  getYearLevel = () => {
    let param = {
      code: this.props.code,
    };
    axiosPost(URL.selectYearLevelByTeacher, param, (response) => {
      if (response.status === 200) {
        const yearLevels = response.data.data;
        if (yearLevels.length > 0) {
          this.setState({ showSubjectAssessmentSpinner: true });
        } else {
          this.setState({ showSubjectAssessmentSpinner: false });
        }
        this.setState(
          {
            yearLevels,
          },
          () => {
            if (this.state.yearLevels !== null) {
              let yearLevels = [...this.state.yearLevels];
              yearLevels.forEach((level) => {
                level.isYearLevelOpen = false;
              });
              this.setState({ yearLevels });
            }
          }
        );
      }
    });
  };

  yearLevelToggle = (yearIndex) => {
    let yearLevels = [...this.state.yearLevels];

    yearLevels.forEach((yearLevel, i) => {
      yearIndex === i
        ? (yearLevel.isYearLevelOpen = !yearLevel.isYearLevelOpen)
        : (yearLevel.isYearLevelOpen = false);
    });
    this.setState({ yearLevels });
  };

  render() {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            Subject Assessment
            {checkWidgetAdminOrNot(this.props.name) === true ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>

          <div className="tt-newListBox">
          <SubjectAssesmentRevampCollapsible
              name="yearLevels"
              code={this.props.code}
            />

            {/* {this.state.yearLevels ? (
              this.state.yearLevels.length > 0 ? (
                this.state.yearLevels.map((yearLevel, yearLvlIdx) => {
                  return (
                    <>
                     
                      <SubjectAssessmentCollapsible
                        header={"Class " + yearLevel.name}
                        yearLevelId={yearLevel.id}
                        isOpen={yearLevel.isYearLevelOpen}
                        name="yearLevels"
                        yearLvlIdx={yearLvlIdx}
                        idObject={{
                          yearLevelId: yearLevel.id,
                          educationLevelId: yearLevel.educationLevelId,
                        }}
                        yearLevelToggle={this.yearLevelToggle}
                        isYearLevelOpen={yearLevel.isYearLevelOpen}
                        yearLevelName={yearLevel.name}
                        code={this.props.code}
                        widgetName={this.props.name}
                      />
                    </>
                  );
                })
              ) : this.state.showSubjectAssessmentSpinner ? (
                <div className="text-center">
                  <Spinner color="primary"></Spinner>
                  <br></br>
                  Loading Data ...
                </div>
              ) : (
                <div className="text-center">No records found.</div>
              )
            ) : (
              <div className="text-center">No records found.</div>
            )} */}
          </div>
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Reports"
              header="View Subject Assessment"
              activityName="select-subject-assessment"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}

export default SubjectAssessment;
