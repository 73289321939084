import React, { Component } from "react";
import Header from "../../components/Header/Header";
import SideNav from "../../components/Sidebar/Sidebar";
import DashboardRoutes from "../Routes/DashboardRoutes";
import { checkRolePermissions } from "../../utils/Permissions";
import { moduleName, URL } from "../../utils/Constants";
import { ProSidebarProvider } from "react-pro-sidebar";
import { axiosPost } from "../../utils/AxiosApi";
import DashboardWidget from "./DashboardWidget";

class DashboardTemplate extends Component {
  componentDidMount() {}

  state = {
    screenWidth: null,
    menus: [
      {
        name: "Dashboard",
        nameNp: "ड्यासबोर्ड",
        iconName: "dashboard",
        link: "/dashboard/main",
        display: checkRolePermissions("Select", moduleName.dashboard),
      },
      {
        name: "Student Materials",
        nameNp: "विद्यार्थी सामाग्री",
        iconName: "local_library",
        link: "/dashboard/student-material",
        display: 0,
      },
      {
        name: "Dictionary",
        nameNp: "शब्दकोश",
        iconName: "local_library",
        link: "/dashboard/dictionary",
        display: 0,
      },
      {
        name: "Calculator",
        nameNp: "क्यालकुलेटर",
        iconName: "calculate",
        link: "/dashboard/customCalculator",
        display: 0,
      },
      {
        name: "Teacher Materials",
        nameNp: "शिक्षक सामाग्री",
        iconName: "auto_stories",
        link: "/dashboard/teacher-material",
        display: 0,
      },
      {
        name: "Relative Links",
        nameNp: "सापेक्ष लिङ्कहरू",
        iconName: "auto_stories",
        link: "/dashboard/relative-links",
        display: 0,
      },
      {
        name: "Training",
        nameNp: "शिक्षक प्रशिक्षण",
        iconName: "groups",
        link: "",
        display: checkRolePermissions("Select", moduleName.TRAINING),
        subMenus: [
          {
            name: "Training",
            nameNp: "प्रशिक्षण",
            iconName: "groups",
            link: "/dashboard/currentTraining",
            display: checkRolePermissions("Select", moduleName.TRAINING),
          },
          {
            name: "Syllabus",
            nameNp: "पाठ्यक्रम",
            iconName: "manage_accounts",
            link: "/dashboard/teacherTraining",
            display: checkRolePermissions("Select", moduleName.SYLLABUS),
          },
        ],
      },
      {
        name: "Reading Materials",
        nameNp: "पठन सामग्री",
        iconName: "library_books",
        link: "",
        display:
          checkRolePermissions("Select", moduleName.READING_MATERIALS) ||
          checkRolePermissions("Select", moduleName.CURRICULUM) ||
          checkRolePermissions("Select", moduleName.TEXTBOOKS) ||
          checkRolePermissions("Select", moduleName.QUESTIONBANK) ||
          checkRolePermissions("Select", moduleName.AUDIO_VISUAL) ||
          checkRolePermissions("Select", moduleName.SIGN_LANGUAGE),
        subMenus: [
          {
            name: "School Curricula",
            nameNp: " पाठ्यक्रमहरू",
            iconName: "article",
            link: "/dashboard/centralCurriculum",
            display: checkRolePermissions("Select", moduleName.CURRICULUM),
          },
          {
            name: "Textbooks",
            nameNp: "पाठ्यपुस्तकहरू",
            iconName: "library_books",
            link: "/dashboard/centralTextbooks",
            display: checkRolePermissions("Select", moduleName.TEXTBOOKS),
          },
          {
            name: "Question Banks",
            iconName: "library_books",
            nameNp: "प्रश्न बैंकहरू",
            link: "/dashboard/centralQuestionBanks",
            display: checkRolePermissions("Select", moduleName.QUESTIONBANK),
          },
          {
            name: "Audio Visuals",
            nameNp: "अडियो भिजुअलहरू",
            iconName: "video_settings",
            link: "/dashboard/centralAudioVisual",
            display: checkRolePermissions("Select", moduleName.AUDIO_VISUAL),
          },
          {
            name: "Sign Languages",
            nameNp: "सांकेतिक भाषाहरू",
            iconName: "sign_language",
            link: "/dashboard/centralSignLanguage",
            display: checkRolePermissions("Select", moduleName.SIGN_LANGUAGE),
          },
        ],
      },
      {
        name: "Game Based Learning",
        nameNp: "खेल आधारित शिक्षा",
        iconName: "sports_esports",
        link: "",
        display:
          checkRolePermissions("Select", moduleName.GAME_BASED_LEARNING) ||
          checkRolePermissions("Select", moduleName.MCQ) ||
          checkRolePermissions("Select", moduleName.FLASHCARD),
        subMenus: [
          {
            name: "MCQ",
            nameNp: "एम.सी.किउ",
            iconName: "quiz",
            link: "/dashboard/centralMCQ",
            display: checkRolePermissions("Select", moduleName.MCQ),
          },
          {
            name: "Flash Cards",
            nameNp: "फ्ल्यास कार्डहरू",
            iconName: "style",
            link: "/dashboard/centralFlashCards",
            display: checkRolePermissions("Select", moduleName.FLASHCARD),
          },
        ],
      },
      {
        name: "Video Conference",
        nameNp: "भिडिओ कन्फरेन्स",
        iconName: "auto_stories",
        link: "/dashboard/video-conference",
        display: checkRolePermissions("Select", moduleName.VIDEO_CONFERENCE),
      },
      {
        name: "Exams",
        nameNp: "जाँच",
        iconName: "design_services",
        link: "",
        display:
          checkRolePermissions("Select", moduleName.EXAM) ||
          checkRolePermissions("Select", moduleName.EXAM_CONFIG) ||
          checkRolePermissions("Select", moduleName.EXAM_LIST) ||
          checkRolePermissions("Select", moduleName.CHECK_EXAM),
        subMenus: [
          {
            name: "Exam Config",
            nameNp: "जाँच कन्फिगुरेशन",
            iconName: "settings_suggest",
            link: "/dashboard/examconfig",
            display: checkRolePermissions("Select", moduleName.EXAM_CONFIG),
          },
          {
            name: "Exam List",
            iconName: "receipt_long",
            link: "/dashboard/examconfigList",
            display: checkRolePermissions("Select", moduleName.EXAM_LIST),
          },
          {
            name: "Check Exam",
            nameNp: "जाँच चेक",
            iconName: "playlist_add_check",
            link: "/dashboard/checkExam",
            display: checkRolePermissions("Select", moduleName.CHECK_EXAM),
          },
        ],
      },
      {
        name: "Calendar",
        nameNp: "पात्रो",
        iconName: "calendar_month",
        link: "",
        display:
          checkRolePermissions("Select", moduleName.eventType) ||
          checkRolePermissions("Select", moduleName.events) ||
          checkRolePermissions("Select", moduleName.calendar),
        subMenus: [
          {
            name: "View Calendar",
            nameNp: "पात्रो हेर्ने",
            link: "/dashboard/view_calendar",
            iconName: "calendar_view_month",
            display: checkRolePermissions("Select", moduleName.calendar),
          },
          {
            name: "Insert Events",
            nameNp: "इभेन्ट हाल्ने",
            link: "/dashboard/calendar_events",
            iconName: "event",
            display:
              checkRolePermissions("Select", moduleName.eventType) ||
              checkRolePermissions("Select", moduleName.events),
          },
        ],
      },
      {
        name: "Master Configuration",
        nameNp: "मास्टर कन्फिगुरेशन",
        iconName: "content_copy",
        link: "",
        display: 1,
        subMenus: [
          {
            name: "Education Level",
            nameNp: "एजुकेशन लेभल",
            iconName: "school",
            link: "/dashboard/educationLevel",
            display: 1,
          },
          {
            name: "Classes",
            nameNp: "कक्षा",
            iconName: "class",
            link: "/dashboard/class",
            display: 1,
          },
          {
            name: "Subject",
            nameNp: "बिषय",
            iconName: "auto_stories",
            link: "/dashboard/subject",
            display: 1,
          },

          {
            name: "Syllabus and Resources",
            nameNp: "पाठ्यक्रम तथा सामग्री",
            iconName: "manage_accounts",
            link: "/dashboard/courseplan",
            display: 1,
          },
          ,
        ],
      },
      {
        name: "Website Configuration",
        nameNp: "वेबसाइट कन्फिगुरेशन",
        iconName: "settings_applications",
        link: "",
        display:
          checkRolePermissions("Select", moduleName.WEBSITE_CONFIGURATION) ||
          checkRolePermissions("Select", moduleName.SLIDER) ||
          checkRolePermissions("Select", moduleName.MENU) ||
          checkRolePermissions("Select", moduleName.MAIN_FEATURE) ||
          checkRolePermissions("Select", moduleName.SITE_CONFIGURATION),
        subMenus: [
          {
            name: "Slider",
            nameNp: "स्लाईडर",
            iconName: "filter",
            link: "/dashboard/slider",
            display: checkRolePermissions("Select", moduleName.SLIDER),
          },
          // {
          //   name: "Education Level",
          //   iconName: "school",
          //   link: "/dashboard/educationLevel",
          //   display: 1,
          // },
          // {
          //   name: "Class",
          //   iconName: "class",
          //   link: "/dashboard/class",
          //   display: 1,
          // },
          // {
          //   name: "Subject",
          //   iconName: "auto_stories",
          //   link: "/dashboard/subject",
          //   display: 1,
          // },
          {
            name: "Menu",
            nameNp: "मेनु",
            iconName: "menu",
            link: "/dashboard/menu",
            display: checkRolePermissions("Select", moduleName.MENU),
          },

          {
            name: "Main Feature",
            nameNp: "मेन विशेषता",
            iconName: "manage_accounts",
            link: "/dashboard/mainFeature",
            display: checkRolePermissions("Select", moduleName.MAIN_FEATURE),
          },

          // {
          //   name: "Teacher Course Plan",
          //   iconName: "manage_accounts",
          //   link: "/dashboard/courseplanTeacher",
          //   display: 1,
          // },
          {
            name: "Site Configuration",
            nameNp: "साइट कन्फिगरेसन",
            iconName: "settings",
            link: "/dashboard/website-config",
            display: checkRolePermissions(
              "Select",
              moduleName.SITE_CONFIGURATION
            ),
          },
          // {
          //   name: "Footer Configuration",
          //   iconName: "settings",
          //   link: "/dashboard/footer-config",
          //   display: 1,
          // },
          ,
        ],
      },
      // {
      //   name: "Student Course Plan",
      //   iconName: "manage_accounts",
      //   link: "/dashboard/courseplan",
      //   display: 1,
      // },
      {
        name: "School Management",
        nameNp: "विद्यालय व्यवस्थापन",
        iconName: "maps_home_work",
        link: "/dashboard/schools",
        // display: checkRolePermissions("Select", moduleName.schoolManagement),
        display: checkRolePermissions("Select", moduleName.SCHOOL_MANAGEMENT),
      },
      {
        name: "User Management",
        nameNp: "प्रयोगकर्ता व्यवस्थापन",
        iconName: "people",
        link: "/dashboard/user",
        // display: checkRolePermissions("Select", moduleName.userManagement),
        display: checkRolePermissions("Select", moduleName.USER_MANAGEMENT),
      },
      {
        name: "Groups",
        nameNp: "ग्रुप",
        iconName: "groups",
        link: "/dashboard/group",
        display: checkRolePermissions("Select", moduleName.GROUPS),
      },
      {
        name: "Discussion",
        nameNp: "छलफल",
        iconName: "co_present",
        link: "/dashboard/discussion",
        display: checkRolePermissions("Select", moduleName.DISCUSSION),
      },
      {
        name: "Role Management",
        nameNp: "भूमिका व्यवस्थापन",
        iconName: "tune",
        link: "",
        // display:
        //   checkRolePermissions("Select", moduleName.modules) ||
        //   checkRolePermissions("Select", moduleName.roles),
        display:
          checkRolePermissions("Select", moduleName.ROLE_MANAGEMENT) ||
          checkRolePermissions("Select", moduleName.MODULE) ||
          checkRolePermissions("Select", moduleName.ROLES),
        subMenus: [
          {
            name: "Modules",
            nameNp: "मोड्युलहरू",
            iconName: "view_module",
            link: "/dashboard/modules",
            display: checkRolePermissions("Select", moduleName.MODULE),
          },
          {
            name: "Roles",
            nameNp: "भूमिकाहरू",
            iconName: "manage_accounts",
            link: "/dashboard/roles_permission",
            display: checkRolePermissions("Select", moduleName.ROLES),
          },
        ],
      },
      {
        name: "Other Materials",
        nameNp: "अन्य सामाग्री",
        iconName: "dynamic_feed",
        link: "",
        display:
          checkRolePermissions("Select", moduleName.OTHER_ACTIVITIES) ||
          checkRolePermissions("Select", moduleName.CATEGORY),
        subMenus: [
          {
            name: "Category",
            nameNp: "अन्य गतिविधि",
            iconName: "view_module",
            link: "/dashboard/otherActivityCategory",
            display: checkRolePermissions("Select", moduleName.CATEGORY),
          },
        ],
      },
      {
        name: "Relative Links",
        nameNp: "सापेक्ष लिङ्कहरू",
        iconName: "link",
        link: "/dashboard/relativeLinks",
        display: checkRolePermissions("Select", moduleName.RELATIVE_LINKS),
        // display: checkRolePermissions("Select", moduleName.myProfile)
      },
      {
        name: "Reports",
        nameNp: "रिपोर्ट",
        iconName: "article",
        link: "",
        display:
          checkRolePermissions("Select", moduleName.REPORTS) ||
          checkRolePermissions("Select", moduleName.SCHOOL_STATISTICS) ||
          checkRolePermissions("Select", moduleName.USER_LOGIN_STATISTICS),
        subMenus: [
          {
            name: "School Statistics",
            nameNp: "विद्यालय तथ्याङ्क",
            iconName: "bar_chart",
            link: "/dashboard/schoolStatistics",
            display: checkRolePermissions(
              "Select",
              moduleName.SCHOOL_STATISTICS
            ),
          },
          {
            name: "User Login Statistics",
            nameNp: "लग-इन तथ्याङ्क",
            iconName: "transfer_within_a_station",
            link: "/dashboard/userLoginStatistics",
            display: checkRolePermissions(
              "Select",
              moduleName.USER_LOGIN_STATISTICS
            ),
          },
          {
            name: "Resources Summary",
            nameNp: "स्रोतहरूको सारांश",
            iconName: "inventory_2",
            link: "/dashboard/resourcesSummary",
            display: true,
            // display: checkRolePermissions(
            //   "Select",
            //   moduleName.RESOURCES_SUMMARY
            // ),
          },
          {
            name: "Academic Repository",
            nameNp: "शैक्षिक भण्डार",
            iconName: "folder_special",
            link: "/dashboard/academicCentralRepo",
            display: true,
            // display: checkRolePermissions(
            //   "Select",
            //   moduleName.RESOURCES_SUMMARY
            // ),
          },
        ],
      },
      {
        name: "My Profile",
        nameNp: "मेरो प्रोफाइल",
        iconName: "person",
        link: "/dashboard/profile",
        display: checkRolePermissions("Select", moduleName.MY_PROFILE),
      },
      {
        name: "Feedbacks",
        nameNp: "प्रतिक्रिया",
        iconName: "comment",
        link: "/dashboard/feedbacks",
        display: checkRolePermissions("Select", moduleName.FEEDBACK),
      },

      {
        name: "Notices",
        nameNp: "नोटिस",
        iconName: "event",
        link: "/dashboard/notices",
        display: checkRolePermissions("Select", moduleName.NOTICES),
      },

      {
        name: "Log Out",
        nameNp: "लग-आउट",
        iconName: "logout",
        link: "/",
        display: true,
      },
    ],
    parentMenu: null,
    selectedMenu: null,
  };

  componentDidMount() {
    this.getWebsiteConfig();
    this.toggleWidgets();
  }

  toggleWidgets = () => {
    let width = window.innerWidth;

    if (width < 768) {
      this.state.menus.forEach((m) => {
        if (m.name === "Teacher Materials") {
          m.display = 1;
        }
        if (m.name === "Calculator") {
          m.display = 1;
        }
        if (m.name === "Dictionary") {
          m.display = 1;
        }
        if (m.name === "Student Materials") {
          m.display = 1;
        }
        if (m.name === "Relative Links") {
          m.display = 1;
        }
      });
    }
    // else {
    //   this.state.menus.forEach((m) => {
    //     if (m.name === "Teacher Materials") {
    //       m.display = 0;
    //     }
    //     if (m.name === "Calculator") {
    //       m.display = 0;
    //     }
    //     if (m.name === "Dictionary") {
    //       m.display = 0;
    //     }
    //     if (m.name === "Student Materials") {
    //       m.display = 0;
    //     }
    //   });
    // }
  };

  getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          document.title = response.data.data[0].title;
        } else {
          document.title = " Learning Portal";
        }
      }
    });
  };

  handleClick = (menu, idx) => {
    let stateMenu = this.state.menus.filter((el) => el.display);

    if (idx) {
      let parentMenu = stateMenu[idx];
      this.setState({ parentMenu, selectedMenu: menu });
    } else {
      this.setState({ selectedMenu: menu, parentMenu: null });
    }
    this.handleMobileSidebar();
  };

  handleMobileSidebar = () => {
    const checkElement = document.getElementById("mobileSidebar");
    if (window.getComputedStyle(checkElement).display === "block") {
      const element = document.getElementsByClassName("sidebarCss")[0];
      if (element.style.display === "" || element.style.display === "none") {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  render() {
    return (
      <ProSidebarProvider>
        <div style={{ display: "flex" }}>
          <SideNav
            menus={this.state.menus.filter((el) => el.display)}
            handleClick={this.handleClick}
            handleMobileSidebar={this.handleMobileSidebar}
          />
          <div
            style={{
              flex: 1,
              height: "100vh",
              overflow: "auto",
              position: "relative",
              justifyContent: "space-around",
            }}
          >
            <div className="mainContainerDashboard">
              <Header
                selectedMenu={this.state.selectedMenu}
                parentMenu={this.state.parentMenu}
              ></Header>
              <div className="mid-container">
                <DashboardRoutes></DashboardRoutes>
              </div>
            </div>
            <DashboardWidget handleClick={this.handleClick} />
          </div>
        </div>
      </ProSidebarProvider>
    );
  }
}

export default DashboardTemplate;
