import React, { useEffect, useState } from 'react';
import StudentCollapsibleGeneralAsseRevamp from '../../../UI/StudentCollapsibleGeneralAsseRevamp';
import { checkRolePermissions, checkSubsTeacherPermissions, draggable, URL } from '../../../../utils/Constants';
import swal from "sweetalert";
import { MESSAGEHEADER } from '../../../../utils/DisplayMessage';
import { axiosPost } from '../../../../utils/AxiosApi';
import { displaySuccessAlert } from '../../../../utils/Utils';
import { connect } from 'react-redux';

import {
    getYearLevelForPublishAssessmentReport,
    getGeneralAssessmentList,
    handleCheckAssessmentFinalize,
    handleCheckFinalizeClickable,
  } from "../../../../ducks/PrimaryARRDucks";

function GeneralAssesmentListRevamp(props) {
    const [educationLevelDivisionId, setEducationLevelDivisionId] = useState(null);
    const [educationLevelId, setEducationLevelId] = useState(null);
    const [classroomId, setClassroomId] = useState(null);
    const [admissionId, setAdmissionId] = useState(null);
    const [studentId, setStudentId] = useState(null);

    const [finalizeGeneralPermission, setFinalizeGeneralPermission] = useState(null);
    const [unFinalizeGeneralPermission, setUnFinalizeGeneralPermission] = useState(null);

    // const [finalize, setFinalize] = useState(null);
    // const [finalizeClickable, setFinalizeClickable] = useState(null);


    useEffect(() => {
        if (props?.assessmentSubjectValue?.assessmentId) {
            const filterData = props?.assessmentList?.filter((item) => item?.assessmentId === props?.assessmentSubjectValue?.assessmentId)[0];
            const educationLevelDivisionId = filterData?.educationLevelDivisionId
            const educationLevelId = filterData?.educationLevelId;
            const classroomId = filterData?.classroomId;

            setEducationLevelDivisionId(educationLevelDivisionId);
            setEducationLevelId(educationLevelId);
            setClassroomId(classroomId)
        }
    }, [props?.assessmentSubjectValue]);


    useEffect(() => {
        checkPermissions();
    }, []);

    const checkPermissions = () => {
        let finalizeGeneralPermission =
            checkRolePermissions(
                "finalize-general-assessment",
                "activity",
                props?.widgetName
            ) ||
            checkSubsTeacherPermissions(
                "finalize-general-assessment",
                "activity",
                props?.widgetName
            );

        let unFinalizeGeneralPermission =
            checkRolePermissions(
                "unfinalize-general-assessment",
                "activity",
                props?.widgetName
            ) ||
            checkSubsTeacherPermissions(
                "unfinalize-general-assessment",
                "activity",
                props?.widgetName
            );

        setFinalizeGeneralPermission(finalizeGeneralPermission);
        setUnFinalizeGeneralPermission(unFinalizeGeneralPermission);
    };

    const handleFinalizeConfirm = (e) => {
        swal({
            title: "Are you sure?",
            text: props.finalize
                ? "Are you sure, you want to unfinalize assessment ?"
                : "Are you sure, you want to finalize assessment ?",
            // icon: "warning",
            closeOnClickOutside: false,
            allowOutsideClick: false,
            buttons: true,
            dangerMode: true,
        }).then((deleteConfirm) => {
            if (deleteConfirm) {
                handleIsFinalizeClick();
            } else {
                swal("You clicked cancel");
            }
        });
        draggable();
    };

    const handleIsFinalizeClick = (e) => {
        let param = {
            yearLevelId: props?.classValue?.id,
            classroomId: classroomId,
            assessmentId: props?.assessmentSubjectValue?.assessmentId,
            isFinalized: true,
        };

        axiosPost(URL.studentGeneralAssessmentFinalize, param, (response) => {
            if (response.status === 200) {
                props.setSubjectFinalize(true);
                props.setFinalizeClickable(false);
                if (props.finalize) {
                    swal(MESSAGEHEADER.success, "Assessment successfully unfinalized.");
                } else {
                    swal(MESSAGEHEADER.success, "Assessment successfully finalized.");
                }
                props.handleCheckAssessmentFinalize(
                    props?.assessmentSubjectValue?.assessmentId,
                    classroomId,
                    props?.classValue?.id
                );
                props.getYearLevelForPublishAssessmentReport(
                    props.assessmentModuleCode
                );
            }
        });
    };

    const handleUnFinalizeConfirm = () => {
        swal({
            title: "Are you sure?",
            text: "Are you sure, you want to unfinalize assessment ?",
            closeOnClickOutside: false,
            allowOutsideClick: false,
            buttons: true,
            dangerMode: true,
        }).then((deleteConfirm) => {
            if (deleteConfirm) {
                handleUnFinalizeClick();
                props.setSubjectFinalize(false);
                props.setFinalizeClickable(true);
            } else {
                swal("You clicked cancel");
            }
        });
        draggable();
    };

    const handleUnFinalizeClick = () => {
        let param = {
            yearLevelId: props?.classValue?.id,
            classroomId: classroomId,
            assessmentId: props?.assessmentSubjectValue?.assessmentId,
            isFinalized: false,
        };

        axiosPost(URL.studentGeneralAssessmentFinalize, param, (response) => {
            if (response.status === 200) {
                displaySuccessAlert(response);
                props.handleCheckAssessmentFinalize(
                    props?.assessmentSubjectValue?.assessmentId,
                    classroomId,
                    props?.classValue?.id
                );
                props.getYearLevelForPublishAssessmentReport(
                    props.assessmentModuleCode
                );
            }
        });
    };

    return (
        <>

            {(props?.classValue !== null && props?.classRooms !== null && props?.assessmentSubjectValue !== null) ?
                (
                    <>
                        {
                            props?.studentData?.map((student, studentIdx) => {
                                return (
                                    <>
                                        <StudentCollapsibleGeneralAsseRevamp
                                            name={"student"}
                                            handleStudentClick={props.handleStudentClick}
                                            studentIdx={studentIdx}
                                            classroomId={student.classroomId}
                                            assessmentId={props?.assessmentSubjectValue?.assessmentId}
                                            // assessId={props?.assessmentSubjectValue?.assessmentId}
                                            admissionId={student.admissionId}
                                            studentId={student.people.id}
                                            isStudentOpen={student.isStudentOpen}
                                            educationLevelDivisionId={educationLevelDivisionId}
                                            educationLevelId={educationLevelId}
                                            // yearLvlId={props?.classValue?.id}
                                            yearLId={props?.classValue?.id}
                                            yearId={props?.classValue?.id}
                                            studentList={props.studentData}
                                            assessmentModuleCode={props?.assessmentModuleCode}
                                            code={props?.code}
                                            widgetName={props?.widgetName}
                                            // assessmentList={props.assessmentList}
                                            header={
                                                <div className="tt-student-list">
                                                    <span>
                                                        {student.people
                                                            ? student.people.name
                                                            : "Student Name"}
                                                        ,
                                                    </span>
                                                    <span className="tt-student-list-subheader">
                                                        {student.people ? student.people.gender : null}
                                                    </span>
                                                    <span
                                                        className="tt-general-assessment-classroom-list-header"
                                                        style={{ marginTop: "-3px" }}
                                                    >
                                                        {studentIdx + 1} of{" "}
                                                        {props.studentData.length} Records
                                                    </span>
                                                </div>
                                            }
                                            setSubjectFinalize={props.setSubjectFinalize}
                                            setFinalizeClickable={props.setFinalizeClickable}

                                        />
                                    </>
                                )
                            })
                        }
                        <div className="text-right tt-generalAssessment-student-footer">
                            {finalizeGeneralPermission && (
                                <button
                                    className="tt-button tt-button-primary mr-2"
                                    onClick={(e) => handleFinalizeConfirm(e)}
                                    disabled={
                                        props.finalizeClickables &&
                                        props.subjectFinalize === false
                                            ? false
                                            : true
                                    }
                                >
                                    Finalize assessment
                                </button>
                            )}
                            {unFinalizeGeneralPermission && (
                                <button
                                    className="tt-button tt-button-primary ml-2"
                                    onClick={(e) => handleUnFinalizeConfirm(e)}
                                    disabled={props.subjectFinalize ? false : true}
                                >
                                    Unfinalize assessment
                                </button>
                            )}
                        </div>
                    </>) :
                (
                    <>
                        <div className="text-center">No records found.</div>
                    </>)}
        </>
    )
}


const mapStateToProps = (state, props) => ({
  finalize: state.primaryArr.finalize,
  finalizeClickable: state.primaryArr.finalizeClickable,
});

const mapActionsToProps = {
  getYearLevelForPublishAssessmentReport:
    getYearLevelForPublishAssessmentReport,
  getGeneralAssessmentList: getGeneralAssessmentList,
  handleCheckAssessmentFinalize: handleCheckAssessmentFinalize,
  handleCheckFinalizeClickable: handleCheckFinalizeClickable,
};

export default connect(mapStateToProps, mapActionsToProps)(GeneralAssesmentListRevamp);
