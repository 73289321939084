import React, { Component } from "react";
import { Collapse, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import SubjectListTableRow from "./SubjectListTableRow";
import GradeAssessmentForm from "./GeneralAssessmentForm";

class GradeSection extends Component {
  state = {};

  tableRow = (subjects, item, classroomId, yearLevelIndex, classroomIndex) => {
    return subjects !== undefined
      ? subjects.map((subject, subjectIndex) => (
          <SubjectListTableRow
            key={yearLevelIndex + classroomIndex + subjectIndex}
            unKey={yearLevelIndex + classroomIndex + subjectIndex}
            subject={subject}
            classroomId={classroomId}
            handleChange={this.props.handleChange}
            idx={subject.subjectId}
            yearLevelIndex={yearLevelIndex}
            classroomIndex={classroomIndex}
            subjectIndex={subjectIndex}
            setDeadLine={this.props.setDeadLine}
            minStartDate={this.props.minStartDate}
            maxEndDate={this.props.maxEndDate}
            handleSelectRowCheckBoxClick={
              this.props.handleSelectRowCheckBoxClick
            }
          />
        ))
      : null;
  };

  render() {
    return (
      <div>
        {this.props.selectedYearLevels ? (
          this.props.selectedYearLevels.map((item, yearLevelIndex) => (
            <>
              <div
                className="row tt-create-grade-tables-header"
                key={yearLevelIndex}
                onClick={(e) => this.props.toggleClasses(e, yearLevelIndex)}
              >
                <div className="col-md-10">
                  <h4 className="tt-class-header">
                    <label>
                      {/* {getYearLevel ? getYearLevel.toUpperCase() : "GRADE"}{" "} */}
                      {/* {localStorage.getItem("yearLevel").toUpperCase()}{" "} */}
                      {item.label.toUpperCase()}
                    </label>
                  </h4>
                </div>
                <div className="col-md-2">
                  <span
                    style={{
                      fontSize: "20px",
                      position: "absolute",
                      top: "6px",
                      right: "15px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={item.isOpen ? faAngleUp : faAngleDown}
                    />
                  </span>
                </div>
              </div>
              <Collapse isOpen={item.isOpen}>
                <div className="tt-create-grade-tables">
                  <div className="card-body">
                    {item.classrooms.length !== 0 ? (
                      item.classrooms.map((classroom, classroomIndex) => (
                        <>
                          <div
                            className="row tt-create-grade-tables-header sub-grade-tables-header"
                            key={classroomIndex}
                            onClick={(e) =>
                              this.props.toggleClasses(
                                e,
                                yearLevelIndex,
                                classroom.id
                              )
                            }
                          >
                            <div className="col-md-10">
                              <h5 className="tt-class-header">
                                {/* <label className="mt-1">
                                  {getClassroomName
                                    ? getClassroomName.toUpperCase()
                                    : "Section"}
                                </label> */}
                                <label className="mt-1 ml-3">
                                  {classroom.name.toUpperCase()}
                                </label>
                              </h5>
                            </div>
                            <div className="col-md-2">
                              <span
                                className="tt-font-down-icon down-icon-sub"
                                style={{
                                  fontSize: "20px",
                                  position: "absolute",
                                  top: "6px",
                                  right: "15px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    classroom.isOpen ? faAngleUp : faAngleDown
                                  }
                                />
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Collapse isOpen={classroom.isOpen}>
                                <table className="table table-bordered table-striped tt-create-assessment-maintable table-responsive">
                                  <thead className="tt-group-header">
                                    <tr>
                                      <th>
                                        <input
                                          type="checkbox"
                                          onClick={(e) =>
                                            this.props.handleSelectAllCheckBoxClick(
                                              e,
                                              classroom.id
                                            )
                                          }
                                        />
                                      </th>
                                      <th>Subjects</th>
                                      <th>Theory/Practical</th>
                                      <th width="350px">
                                        Assessment Date(from/to)
                                      </th>
                                      <th>Recording Date33</th>
                                      <th>Recording Deadline</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {classroom.subjects.length > 0 ? (
                                      this.tableRow(
                                        classroom.subjects,
                                        item,
                                        classroom.id,
                                        yearLevelIndex,
                                        classroomIndex
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={6}>
                                          No subjects are assigned for this
                                          section. Assign Subjects first
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>

                                <GradeAssessmentForm
                                  yearLevel={item}
                                  classroom={classroom}
                                  yearLevelId={item.value}
                                  classroomId={classroom.id}
                                  yearLevelIndex={yearLevelIndex}
                                  minStartDate={this.props.minStartDate}
                                  maxEndDate={this.props.maxEndDate}
                                  classroomIndex={classroomIndex}
                                  handleGeneralAssessmentCheckbox={
                                    this.props.handleGeneralAssessmentCheckbox
                                  }
                                  handleGeneralAssessmentDateChange={
                                    this.props.handleGeneralAssessmentDateChange
                                  }
                                />
                              </Collapse>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="p-2 text-center">
                        <strong>
                          {"No" + localStorage.getItem("classroom")}
                        </strong>
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </>
          ))
        ) : (
          <div className="tt-subject-list-spinner">
            <Spinner type="grow" color="warning" />
          </div>
        )}
      </div>
    );
  }
}

export default GradeSection;
