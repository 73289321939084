  import React, { useState, useEffect } from "react";

import {
  checkRolePermissions,
  checkSubsTeacherPermissions,
  scaleTypeCode,
  URL,
} from "../../../../utils/Constants";
// import userImage from "../../../../assets/images/user-image.png";
import userImage from "../../../../assets/images//user-image.png";
import { handleError } from "../../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";

const StudentDetailRevamp = (props) => {
  const [commentIdx, setCommentIdx] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [finalizeReportPermission, setFinalizeReportPermission] =
    useState(null);
  const [unFinalizeReportPermission, setUnFinalizeReportPermission] =
    useState(null);
  const [students, setStudents] = useState([]);
  useEffect(() => {
    checkPermissions();
  }, []);

  useEffect(() => {
    setTrigger(!trigger);
    return () => {
      $(".selectpicker").selectpicker("destroy");
    };
  }, [props.studentData]);

  useEffect(() => {
    $(".selectpicker").selectpicker();
    $(".selectpicker").selectpicker("refresh");
    return () => {
      // $(".selectpicker").selectpicker("destroy");
    };
  }, [trigger]);

  const checkPermissions = () => {
    let finalizeReportPermissionCheck =
      checkRolePermissions(
        "finalize-subject-assessment",
        "activity",
        props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "finalize-subject-assessment",
        "activity",
        props.widgetName
      );

    let unFinalizeReportPermissionCheck =
      checkRolePermissions(
        "unfinalize-subject-assessment",
        "activity",
        props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "unfinalize-subject-assessment",
        "activity",
        props.widgetName
      );

    setFinalizeReportPermission(finalizeReportPermissionCheck);
    setUnFinalizeReportPermission(unFinalizeReportPermissionCheck);
  };

  const getTheoryPracticalLength = (item) => {
    if (item.hasTheory && item.hasPractical) {
      return (
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical
          .length +
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory
          .length
      );
    } else if (item.hasTheory) {
      return item.subjectAssessmentScalesTheoryPractcal
        .assessmentScalesForTheory.length;
    } else if (item.hasPractical) {
      return item.subjectAssessmentScalesTheoryPractcal
        .assessmentScalesForPractical.length;
    } else {
      return 0;
    }
  };

  const getTheoryPracticalTitle = (item) => {
    if (item.hasTheory && item.hasPractical) {
      return (
        <>
          {item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
            (el) => (
              <th rowSpan={1}>
                {" "}
                Th {`${el.theoryMax ? `(${el.theoryMax})` : ""}`}
              </th>
            )
          )}
          {item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
            (el) => (
              <th rowSpan={1}>
                Pr {`${el.practicalMax ? `(${el.practicalMax})` : ""}`}
              </th>
            )
          )}
        </>
      );
    } else if (item.hasTheory) {
      return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
        (el) => (
          <th rowSpan={1}>Th {`${el.theoryMax ? `(${el.theoryMax})` : ""}`}</th>
        )
      );
    } else if (item.hasPractical) {
      return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
        (el) => (
          <th rowSpan={1}>Pr {`${el.practicalMax ? `(${el.practicalMax})` : ""}`}</th>
        )
      );
    } else {
      return <></>;
    }
  };

  const getTheoryPracticalTitleData = (item, student) => {
    const filtredStudentSub = student?.subject?.[item.name]?.[0];

    if (!filtredStudentSub) {
      let finalPracticalAssessmentDetail =
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
          (el) => "N/A"
        );

      let finalTheoryAssessmentDetail =
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
          (el) => {
            return "N/A";
          }
        );

      if (item.hasTheory && item.hasPractical) {
        return (
          <>
            {finalPracticalAssessmentDetail.map((assessment) => (
              <td className="text-center">N/A</td>
            ))}
            {finalTheoryAssessmentDetail.map((assessment) => (
              <td className="text-center">N/A</td>
            ))}
          </>
        );
      } else if (item.hasTheory) {
        return (
          <>
            {finalTheoryAssessmentDetail.map((assessment) => (
              <td className="text-center">N/A</td>
            ))}
          </>
        );

        // return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
        //   (el) => <td>td {`(${el.scaleName})`}</td>
        // );
      } else if (item.hasPractical) {
        return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
          (el) => <td>N/A</td>
        );
      } else {
        return <></>;
      }

      // console.log(finalTheoryAssessmentDetail,"final theory assessent detaujl ")

      return;
    }

    // let theoryAssesmentDetail =filtredStudentSub?.theorySubjectAssessments?.filter(
    //     (data) => data.admissionId == student.admissionId
    //   ) ?? [];

    let finalTheoryAssessmentDetail =
      filtredStudentSub?.theorySubjectAssessments;
    // let finalTheoryAssessmentDetail =
    //   item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
    //     (el) => {
    //       let theroyDetail = theoryAssesmentDetail.find(
    //         (itm) => itm.assessmentScaleId == el.id
    //       );
    //       return {
    //         ...theroyDetail,
    //         assessmentScale: el,
    //         assessmentScaleTypeCode: el.assessmentScaleTypeCode,
    //       };
    //     }
    //   );

    let finalPracticalAssessmentDetail =
      filtredStudentSub.practicalSubjectAssessments;
    // let finalPracticalAssessmentDetail =
    //   item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
    //     (el) => {
    //       let practicalDetail = practicalAssesmentDetail.find(
    //         (itm) => itm.assessmentScaleId == el.id
    //       );
    //       return {
    //         ...practicalDetail,
    //         assessmentScale: el,
    //         assessmentScaleTypeCode: el.assessmentScaleTypeCode,
    //       };
    //     }
    //   );

    if (item.hasTheory && item.hasPractical) {
      return (
        <>
          {finalTheoryAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {assessment.isTheoryAbsent ? null : (
                <div>
                  {assessment.assessmentScaleTypeCode ===
                  scaleTypeCode.level ? (
                    <select
                      style={{ Width: "50%" }}
                      value={assessment?.assessmentScaleLevelTheoryId}
                      onChange={(e) =>
                        props.handleAssessmentScaleForTheoryLevelChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      className="form-control selectpicker"
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Level"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleLevels
                        ? assessment.assessmentScale.assessmentScaleLevels.map(
                            (scaleLevel) => (
                              <>
                                {scaleLevel.levelType === "colourStickers" ? (
                                  <option
                                    value={scaleLevel && scaleLevel.id}
                                    style={{
                                      backgroundColor: scaleLevel.level,
                                      color: "#fff",
                                      fontSize: "25px",
                                    }}
                                  >
                                    {scaleLevel.level}
                                  </option>
                                ) : scaleLevel.levelType === "image" ? (
                                  <option
                                    value={scaleLevel.id}
                                    data-content={`<img src=${
                                      URL.imageSelectURL + scaleLevel.level
                                    } class="img-responsive" height="42" width="42"/>`}
                                  ></option>
                                ) : (
                                  <option value={scaleLevel.id}>
                                    {scaleLevel.level}
                                  </option>
                                )}
                              </>
                            )
                          )
                        : null}
                    </select>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.mark ? (
                    assessment.assessmentScale && (
                      <div className="d-flex justify-content-center">
                        <div>
                          <input
                            type="number"
                            min={0}
                            max={assessment.assessmentScale?.theoryMax}
                            value={assessment?.theoryObtainedMarks ?? 0}
                            disabled={assessment.isFinalized ? true : false}
                            style={{
                              border: assessment.isFinalized
                                ? "1px solid grey"
                                : "1px solid black",
                              height: "38px",
                              width: "55px",
                              textAlign: "center",

                              background: assessment.isFinalized
                                ? "#dddddd"
                                : "",
                              color: assessment.isFinalized ? "grey" : "",
                            }}
                            onChange={(count) =>
                              props.theoryCounterHandler(
                                count.target.value,
                                assessment,
                                item.name
                              )
                            }
                            step={0.5}
                          />
                        </div>
                      </div>
                    )
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.gradePoint ? (
                    <select
                      value={
                        assessment?.assessmentScaleGradePointTheoryId ?? ""
                      }
                      onChange={(e) =>
                        props.handleAssessmentScaleTheoryGradePointChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      style={{ Width: "50%" }}
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Grade Point"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleGradePoints
                        ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                            (scaleGradePoint) => (
                              <option value={scaleGradePoint.id}>
                                {scaleGradePoint.grade}
                              </option>
                            )
                          )
                        : null}
                    </select>
                  ) : null}
                </div>
              )}

              <div className="mt-2">
                <input
                  // id={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  type="checkbox"
                  name="isTheoryAbsent"
                  checked={assessment.isTheoryAbsent}
                  disabled={assessment.isFinalized ? true : false}
                  onChange={(e) =>
                    props.handleIsAbsentForTheory(e, assessment, item.name)
                  }
                />
                <label
                  // htmlFor={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  style={{
                    paddingLeft: "5px",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <strong>Is Absent</strong>
                </label>
              </div>
            </td>
          ))}

          {finalPracticalAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {}
              {assessment.isPracticalAbsent ? null : (
                <div>
                  {assessment.assessmentScale.assessmentScaleTypeCode ===
                  scaleTypeCode.level ? (
                    <select
                      style={{ Width: "50%" }}
                      value={assessment?.assessmentScaleLevelPracticalId}
                      onChange={(e) =>
                        props.handleAssessmentScaleForPracticalLevelChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      className="form-control selectpicker"
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Level"}
                      </option>

                      {assessment?.assessmentScale?.assessmentScaleLevels.map(
                        (scaleLevel) => (
                          <>
                            {scaleLevel.levelType === "colourStickers" ? (
                              <option
                                value={scaleLevel.id}
                                style={{
                                  backgroundColor: scaleLevel.level,
                                  color: "#fff",
                                  fontSize: "25px",
                                }}
                              >
                                {scaleLevel.level}
                              </option>
                            ) : scaleLevel.levelType === "image" ? (
                              <option
                                value={scaleLevel.id}
                                data-content={`<img src=${
                                  URL.imageSelectURL + scaleLevel.level
                                } class="img-responsive" height="42" width="42"/>`}
                              ></option>
                            ) : (
                              <option value={scaleLevel.id}>
                                {scaleLevel.level}
                              </option>
                            )}
                          </>
                        )
                      )}
                    </select>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.mark ? (
                    <>
                      <input
                        type="number"
                        max={
                          assessment.assessmentScale
                            ? assessment.assessmentScale.practicalMax
                              ? assessment.assessmentScale.practicalMax
                              : 100
                            : 100
                        }
                        // count={
                        //   assess?.practicalObtainedMarks
                        // }
                        value={assessment?.practicalObtainedMarks ?? 0}
                        disabled={assessment.isFinalized ? true : false}
                        style={{
                          border: assessment.isFinalized
                            ? "1px solid grey"
                            : "1px solid black",
                          height: "38px",
                          width: "55px",
                          textAlign: "center",

                          background: assessment.isFinalized ? "#dddddd" : "",
                          color: assessment.isFinalized ? "grey" : "",
                        }}
                        onChange={(count) =>
                          props.practicalCounterHandler(
                            count.target.value,
                            assessment,
                            item.name
                          )
                        }
                        step={0.5}
                      />
                    </>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.gradePoint ? (
                    <select
                      value={
                        assessment?.assessmentScaleGradePointPracticalId ?? ""
                      }
                      onChange={(e) =>
                        props.handleAssessmentScalePracticalGradePointChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      style={{ Width: "50%" }}
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Grade Point"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleGradePoints
                        ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                            (scaleGradePoint) => (
                              <option value={scaleGradePoint.id}>
                                {scaleGradePoint.grade}
                              </option>
                            )
                          )
                        : null}
                    </select>
                  ) : null}
                </div>
              )}

              <div className="mt-2">
                <input
                  // id={
                  //   "isPracticalAbsent" +
                  //   assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  type="checkbox"
                  name="isPracticalAbsent"
                  checked={assessment?.isPracticalAbsent}
                  disabled={assessment.isFinalized ? true : false}
                  onChange={(e) =>
                    props.handleIsAbsentForPractical(e, assessment, item.name)
                  }
                />
                <label
                  // htmlFor={
                  //   "isPracticalAbsent" +
                  //   assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  style={{
                    paddingLeft: "5px",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <strong>Is Absent</strong>
                </label>
              </div>
            </td>
          ))}
        </>
      );
    } else if (item.hasTheory) {
      return (
        <>
          {finalTheoryAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {assessment.isTheoryAbsent ? null : (
                <div>
                  {assessment.assessmentScaleTypeCode ===
                  scaleTypeCode.level ? (
                    <select
                      style={{ Width: "50%" }}
                      value={assessment?.assessmentScaleLevelTheoryId}
                      onChange={(e) =>
                        props.handleAssessmentScaleForTheoryLevelChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      className="form-control selectpicker"
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Level"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleLevels
                        ? assessment.assessmentScale.assessmentScaleLevels.map(
                            (scaleLevel) => (
                              <>
                                {scaleLevel.levelType === "colourStickers" ? (
                                  <option
                                    value={scaleLevel && scaleLevel.id}
                                    style={{
                                      backgroundColor: scaleLevel.level,
                                      color: "#fff",
                                      fontSize: "25px",
                                    }}
                                  >
                                    {scaleLevel.level}
                                  </option>
                                ) : scaleLevel.levelType === "image" ? (
                                  <option
                                    value={scaleLevel.id}
                                    data-content={`<img src=${
                                      URL.imageSelectURL + scaleLevel.level
                                    } class="img-responsive" height="42" width="42"/>`}
                                  ></option>
                                ) : (
                                  <option value={scaleLevel.id}>
                                    {scaleLevel.level}
                                  </option>
                                )}
                              </>
                            )
                          )
                        : null}
                    </select>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.mark ? (
                    assessment.assessmentScale && (
                      <div className="d-flex justify-content-center">
                        <div>
                          <input
                            type="number"
                            min={0}
                            max={assessment.assessmentScale?.theoryMax}
                            value={assessment?.theoryObtainedMarks ?? 0}
                            disabled={assessment.isFinalized ? true : false}
                            style={{
                              border: assessment.isFinalized
                                ? "1px solid grey"
                                : "1px solid black",
                              height: "38px",
                              width: "55px",
                              textAlign: "center",

                              background: assessment.isFinalized
                                ? "#dddddd"
                                : "",
                              color: assessment.isFinalized ? "grey" : "",
                            }}
                            onChange={(count) =>
                              props.theoryCounterHandler(
                                count.target.value,
                                assessment,
                                item.name
                              )
                            }
                            step={0.5}
                          />
                        </div>
                      </div>
                    )
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.gradePoint ? (
                    <select
                      value={
                        assessment?.assessmentScaleGradePointTheoryId ?? ""
                      }
                      onChange={(e) =>
                        props.handleAssessmentScaleTheoryGradePointChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      style={{ Width: "50%" }}
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Grade Point"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleGradePoints
                        ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                            (scaleGradePoint) => (
                              <option value={scaleGradePoint.id}>
                                {scaleGradePoint.grade}
                              </option>
                            )
                          )
                        : null}
                    </select>
                  ) : null}
                </div>
              )}

              <div className="mt-2">
                <input
                  // id={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  type="checkbox"
                  name="isTheoryAbsent"
                  checked={assessment.isTheoryAbsent}
                  disabled={assessment.isFinalized ? true : false}
                  onChange={(e) =>
                    props.handleIsAbsentForTheory(e, assessment, item.name)
                  }
                />
                <label
                  // htmlFor={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  style={{
                    paddingLeft: "5px",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <strong>Is Absent</strong>
                </label>
              </div>
            </td>
          ))}
        </>
      );

      // return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
      //   (el) => <td>td {`(${el.scaleName})`}</td>
      // );
    } else if (item.hasPractical) {
      // return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
      //   (el) => <td>Pr {`(${el.scaleName})`}</td>
      // );
      return (
        <>
          {finalPracticalAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {assessment.isPracticalAbsent ? null : (
                <div>
                  {assessment.assessmentScaleTypeCode ===
                  scaleTypeCode.level ? (
                    <select
                      style={{ Width: "50%" }}
                      value={assessment?.assessmentScaleLevelPracticalId}
                      onChange={(e) =>
                        props.handleAssessmentScaleForPracticalLevelChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      className="form-control selectpicker"
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Level"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleLevels
                        ? assessment.assessmentScale.assessmentScaleLevels.map(
                            (scaleLevel) => (
                              <>
                                {scaleLevel.levelType === "colourStickers" ? (
                                  <option
                                    value={scaleLevel && scaleLevel.id}
                                    style={{
                                      backgroundColor: scaleLevel.level,
                                      color: "#fff",
                                      fontSize: "25px",
                                    }}
                                  >
                                    {scaleLevel.level}
                                  </option>
                                ) : scaleLevel.levelType === "image" ? (
                                  <option
                                    value={scaleLevel.id}
                                    data-content={`<img src=${
                                      URL.imageSelectURL + scaleLevel.level
                                    } class="img-responsive" height="42" width="42"/>`}
                                  ></option>
                                ) : (
                                  <option value={scaleLevel.id}>
                                    {scaleLevel.level}
                                  </option>
                                )}
                              </>
                            )
                          )
                        : null}
                    </select>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.mark ? (
                    assessment.assessmentScale && (
                      <div className="d-flex justify-content-center">
                        <div>
                          <input
                            type="number"
                            min={0}
                            max={assessment.assessmentScale?.practicalMax}
                            value={assessment?.practicalObtainedMarks ?? 0}
                            disabled={assessment.isFinalized ? true : false}
                            style={{
                              border: assessment.isFinalized
                                ? "1px solid grey"
                                : "1px solid black",
                              height: "38px",
                              width: "55px",
                              textAlign: "center",

                              background: assessment.isFinalized
                                ? "#dddddd"
                                : "",
                              color: assessment.isFinalized ? "grey" : "",
                            }}
                            onChange={(count) =>
                              props.practicalCounterHandler(
                                count.target.value,
                                assessment,
                                item.name
                              )
                            }
                            step={0.5}
                          />
                        </div>
                      </div>
                    )
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.gradePoint ? (
                    <select
                      value={
                        assessment?.assessmentScaleGradePointPracticalId ?? ""
                      }
                      onChange={(e) =>
                        props.handleAssessmentScalePracticalGradePointChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      style={{ Width: "50%" }}
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Grade Point"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleGradePoints
                        ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                            (scaleGradePoint) => (
                              <option value={scaleGradePoint.id}>
                                {scaleGradePoint.grade}
                              </option>
                            )
                          )
                        : null}
                    </select>
                  ) : null}
                </div>
              )}

              <div className="mt-2">
                <input
                  // id={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  type="checkbox"
                  name="isPracticalAbsent"
                  checked={assessment.isPracticalAbsent}
                  disabled={assessment.isFinalized ? true : false}
                  onChange={(e) =>
                    props.handleIsAbsentForPractical(e, assessment, item.name)
                  }
                />
                <label
                  // htmlFor={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  style={{
                    paddingLeft: "5px",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <strong>Is Absent</strong>
                </label>
              </div>
            </td>
          ))}
        </>
      )
    } else {
      return <></>;
    }
  };

  const getFinalizeText = (item) => {
    let practicalFinalize =
      item?.theorySubjectAssessments?.length > 0
        ? item.theorySubjectAssessments.some((item) => item.isFinalized)
        : false;
    let theoryFinalze =
      item?.practicalSubjectAssessments?.length > 0
        ? item.practicalSubjectAssessments.some((item) => item.isFinalized)
        : false;

    return practicalFinalize || theoryFinalze ? "Unfinalize" : "Finalize";
  };

  return (
    <>
      {" "}
      {props.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Spinner color="primary" />

          <p> Loading Data ...</p>
        </div>
      ) : !props.allData.length > 0 ? (
        <div className="container-fluid">
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No Records found
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div
                className="col tt-widgetContent-tab-holder"
                style={{
                  maxHeight: "55vh",
                  marginBottom: "10px",
                  overflowX: "scroll",
                }}
              >
                <table className="table table-striped table-bordered">
                  <thead
                    className="tt-group-header text-center"
                    style={{ position: "sticky", top: "-1px", zIndex: 1 }}
                  >
                    <tr>
                      <th width="100px" rowSpan={2}>
                        Image
                      </th>
                      <th width="130px" rowSpan={2}>
                        Student Name
                      </th>
                      {props.allData.map((item) => (
                        <th colSpan={getTheoryPracticalLength(item)}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column-reverse",
                              gap: "0.5rem",
                            }}
                          >
                            <span>{item?.name}</span>
                            <button
                              disabled={!props.subjectFinalize[item.name]}
                              className="tt-button tt-button-primary ml-2"
                              onClick={() => props.handleFinalize(item)}
                              // disabled={
                              //   props.finalizeClickable && props.finalize === false
                              //     ? false
                              //     : true
                              // }
                            >
                              {getFinalizeText(item)}
                            </button>
                          </div>
                        </th>
                      ))}
                    </tr>

                    <tr>
                      {props.allData.map((item) =>
                        getTheoryPracticalTitle(item)
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {!props.studentData.length > 0 ? (
                      <>No data found</>
                    ) : (
                      props.studentData.map((student, idx) => (
                        <tr key={idx}>
                          <td>
                            <img
                              width="90"
                              height="90"
                              src={
                                student.people.photo
                                  ? URL.imageSelectURL + student.people.photo
                                  : userImage
                              }
                              style={{ objectFit: "cover" }}
                              onError={(e) => handleError(e)}
                            ></img>
                          </td>
                          <td>
                            {student.people
                              ? student.people.name
                              : "Student Name"}
                          </td>
                          {props.allData.map((item) =>
                            getTheoryPracticalTitleData(item, student)
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="text-right">
                {finalizeReportPermission && (
                  <button
                    className="tt-button tt-button-primary mr-2"
                    onClick={props.handleSubmit}
                    // disabled={
                    //   students.length > 0
                    //     ? props.finalize
                    //       ? true
                    //       : false
                    //     : true
                    // }
                  >
                    Save Assessment Records
                  </button>
                )}

                {/* {finalizeReportPermission ? (
                  <button
                    className="tt-button tt-button-primary ml-2"
                    onClick={props.handleStudentSubjectFinalizeConfirm}
                    disabled={
                      props.finalizeClickable && props.finalize === false
                        ? false
                        : true
                    }
                  >
                    Finalize assessment
                  </button>
                ) : null}

                {unFinalizeReportPermission ? (
                  <button
                    className="tt-button tt-button-primary ml-2"
                    onClick={props.handleStudentSubjectUnFinalizeConfirm}
                    disabled={props.finalize ? false : true}
                  >
                    Unfinalize assessment
                  </button>
                ) : null} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StudentDetailRevamp;
