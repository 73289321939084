import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import { URL, checkWidgetAdminOrNot } from "../../../../utils/Constants";
import Collapsible from "../../../UI/Collapsible";
import ModalWindow from "./../../../UI/ModalWindow";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { connect } from "react-redux";
import { getGeneralAssessmentList } from "../../../../ducks/PrimaryARRDucks";
import { Spinner } from "reactstrap";
import GeneralAssessmentCollapsibleRevamp from "./GeneralAssessmentCollapsibleRevamp";

class GeneralAssessmentList extends Component {
  state = {
    generalAssessmentList: [],
    assessmentList: [],
    studentList: [],
    studentListSpinner: true,
  };

  componentDidMount() {
    this.props.getGeneralAssessmentList(this.props.code);
    // let param = {
    //   assessmentModuleCode: this.props.code,
    // };
    // axiosPost(URL.generalAssessmentSelectAll, param, (response) => {
    //   if (response.status === 200) {
    //     this.setState({ generalAssessmentList: response.data.data }, () => {
    //       let generalAssessmentList = [...this.state.generalAssessmentList];

    //       generalAssessmentList.forEach((year) => {
    //         year.isOpenYearLevel = false;

    //         year.classrooms.forEach((classes) => {
    //           classes.isClassRoomOpen = false;
    //         });
    //       });
    //     });
    //   }
    // });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { generalAssessmentList: nextProps.generalAssessmentList };
  }

  handleYearLevelToggle = (index) => {
    let generalAssessmentList = [...this.state.generalAssessmentList];

    generalAssessmentList.forEach((year, i) => {
      index === i
        ? (year.isOpenYearLevel = !year.isOpenYearLevel)
        : (year.isOpenYearLevel = false);
    });

    this.setState({ generalAssessmentList });
  };

  handleToggleClassRoom = (mainIndex, index, classroomId, yearLevelId) => {
    let generalAssessmentList = [...this.state.generalAssessmentList];
    generalAssessmentList.forEach((year, i) => {
      if (mainIndex === i) {
        year.classrooms.forEach((classes, j) => {
          if (j === index) {
            classes.isClassRoomOpen = !classes.isClassRoomOpen;
          } else {
            classes.isClassRoomOpen = false;
          }
        });
      } else {
        year.classrooms.forEach((classes) => {
          classes.isClassRoomOpen = false;
        });
      }
    });

    this.setState({ generalAssessmentList }, () => {
      this.getValuesForClassRoom(classroomId, yearLevelId);
    });
  };

  getValuesForClassRoom = (classroomId, yearLevelId) => {
    let param = {
      classroomId: classroomId,
      yearLevelId: yearLevelId,
    };
    axiosPost(URL.generalAssessmentSelectByYearAndClass, param, (response) => {
      if (response.status === 200) {
        this.setState({ assessmentList: response.data.data }, () => {
          let assessmentList = [...this.state.assessmentList];
          assessmentList.forEach((assess) => {
            assess.isAssessmentOpen = false;
          });
        });
      }
    });
  };

  handleSectionToggle = (assessmentIdx, classroomId, yearLevelId) => {
    let assessmentList = [...this.state.assessmentList];
    assessmentList.forEach((assessment, i) => {
      if (i === assessmentIdx) {
        assessment.isAssessmentOpen = !assessment.isAssessmentOpen;
      } else {
        assessment.isAssessmentOpen = false;
      }
    });

    this.setState({ assessmentList }, () => {
      this.getStudentList(classroomId, yearLevelId);
    });
  };

  getStudentList = (classroomId, yearLevelId) => {
    let param = {
      classroomId: classroomId,
      yearLevelId: yearLevelId,
    };
    this.setState({ studentListSpinner: true }, function () {
      axiosPost(URL.getAssignedStudents, param, (response) => {
        if (response.status === 200) {
          this.setState(
            { studentList: response.data.data, studentListSpinner: false },
            () => {
              let datas = [...this.state.studentList];
              datas.forEach((student) => {
                student.isStudentOpen = false;
              });
              this.setState({ studentList: datas });
            }
          );
        }
      });
    });
  };

  getStudentsList = (data) => {
    this.setState({ studentListSpinner: true }, () => {
      axiosPost(
        URL.getAssignedStudents,
        data,
        (response) => {
          if (response.status === 200) {
            // let students = response.data.data;
            // this.setState({studentList:students})
            // this.setState({ studentListSpinner: false });
            // setStudentData(students);
            // setLoading(false);
            this.setState(
              { studentList: response.data.data, studentListSpinner: false },
              () => {
                let datas = [...this.state.studentList];
                datas.forEach((student) => {
                  student.isStudentOpen = false;
                });
                this.setState({ studentList: datas });
              }
            );
          }
        },
        (err) => {
          // setLoading(false);
          this.setState({ studentListSpinner: false });
        }
      );
    })
    // setLoading(true);

  };


  handleStudentClick = (index) => {
    let studentList = [...this.state.studentList];

    studentList.forEach((student, i) => {
      index === i
        ? (student.isStudentOpen = !student.isStudentOpen)
        : (student.isStudentOpen = false);
    });
    this.setState({ studentList });
  };



  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          General Assessment
          {checkWidgetAdminOrNot(this.props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="tt-newListBox">
          <GeneralAssessmentCollapsibleRevamp
            generalAssessmentListSpinner={this.props.generalAssessmentListSpinner}
            generalAssessmentList={this.state.generalAssessmentList}
            code={this.props.code}

            name="student"
            handleToggleClassRoom={this.handleToggleClassRoom}
            handleSectionToggle={this.handleSectionToggle}
            studentList={this.state.studentList}
            studentListSpinner={this.state.studentListSpinner}
            handleStudentClick={this.handleStudentClick}
            assessmentModuleCode={this.props.code}
            widgetName={this.props.name}
            getStudentsList={this.getStudentsList}

          />

          {/* old code for multi-level accordian */}
          {/* {this.state.generalAssessmentList ? (
            this.state.generalAssessmentList.length > 0 ? (
              this.state.generalAssessmentList.map((yearLevel, yearIdx) => {
                return (
                  <Collapsible
                    header={"Class " + yearLevel.name}
                    key={yearIdx}
                    idx={yearIdx}
                    handleYearLevelToggle={this.handleYearLevelToggle}
                    name="yearLevel"
                    isOpen={yearLevel.isOpenYearLevel}
                    code={this.props.code}
                    collapsibleBody={yearLevel.classrooms.map(
                      (classroom, classroomIdx) => {
                        return (
                          <Collapsible
                            key={classroomIdx}
                            header={classroom.name}
                            isOpen={yearLevel.isOpenYearLevel}
                            classroomId={classroom.id}
                            yearLvlId={yearLevel.id}
                            classroomIdx={classroomIdx}
                            idx={yearIdx}
                            name="classRoom"
                            isOpenClassRoom={classroom.isClassRoomOpen}
                            handleToggleClassRoom={this.handleToggleClassRoom}
                            assessmentList={this.state.assessmentList}
                            handleSectionToggle={this.handleSectionToggle}
                            studentList={this.state.studentList}
                            studentListSpinner={this.state.studentListSpinner}
                            handleStudentClick={this.handleStudentClick}
                            assessmentModuleCode={this.props.code}
                            widgetName={this.props.name}
                          />
                        );
                      }
                    )}
                  />
                );
              })
            ) : this.props.generalAssessmentListSpinner ? (
              <div className="text-center">
                <Spinner color="primary"></Spinner>
                <br></br>
                Loading Data ...
              </div>
            ) : (
              <div className="text-center">No records found.</div>
            )
          ) : (
            <div className="text-center">No records found.</div>
          )} */}
          
        </div>
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Reports"
              header="View General Assessment"
              activityName="select-general-assessment"
            />
          }
        ></ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  generalAssessmentList: state.primaryArr.generalAssessmentList,
  generalAssessmentListSpinner: state.primaryArr.generalAssessmentListSpinner,
});

const mapActionsToProps = {
  getGeneralAssessmentList: getGeneralAssessmentList,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(GeneralAssessmentList);
