import React, { Component } from "react";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  URL,
  checkRolePermissions,
  checkWidgetAdminOrNot,
  draggable,
} from "../../../../utils/Constants";
import { Collapse, Spinner } from "reactstrap";
import ModalWindow from "./../../../UI/ModalWindow";
import GeneralAssessmentSettings from "./../../AssessmentRecord/GeneralAssessmentSettings/GeneralAssessmentSettings";
import SubjectAssessmentSetting from "./../../AssessmentRecord/SubjectAssessmentSetting/SubjectAssessmentSetting";
import swal from "sweetalert";
import { formatDate } from "../../../../utils/Utils";
import GranularPermissionModal from "./../../../Permissions/GranularPermissionModal";
import { connect } from "react-redux";
import { getAssessments } from "../../../../ducks/PrimaryARRDucks";
import CreateAssessments from "../../AssessmentRecord/CreateAssessment/CreateAssessments";
import SubjectAssessmentDetails from "./SubjectAssessmentDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../UI/Pagination";

class Assessments extends Component {
  state = {
    assessments: null,
    generalAssessmentSettingModal: false,
    assessmentName: "",
    idObject: {},
    subjectSettingAssessmentModal: false,
    className: "",
    sectionName: "",
    subjectConfigurationEditable: false,
    updateModal: false,
    updateData: "",
    updateConfig: "",
    subjectSettingDetailModal: false,
    subjectConfig: false,
    generalConfig: false,
    assessmentSpinner: "",
    assessmentTotalRecords: 0,
    assessmentOffset: 0,
    assessmentRowCountLimit: 6,
    assessmentCurrentPage: 1,
    permissionGeneralConfig: false,
    permissionSubjectConfig: false,
    permissionEditAssessment: false,
    permissionCancelAssessment: false,
  };
  componentDidMount() {
    this.props.getAssessments(
      this.props.code,
      this.state.assessmentOffset,
      this.state.assessmentRowCountLimit
    );
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionGeneralConfig: checkRolePermissions(
        "general-assessment-config",
        "activity"
      ),
      permissionSubjectConfig: checkRolePermissions(
        "subject-assessment-config",
        "activity"
      ),
      permissionEditAssessment: checkRolePermissions(
        "edit-assessment",
        "activity",
        this.props.name
      ),
      permissionCancelAssessment: checkRolePermissions(
        "cancel-assessment",
        "activity",
        this.props.name
      ),
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      assessments: nextProps.assessments,
      assessmentTotalRecords: nextProps.assessmentTotalRecords,
    };
  }

  assessmentData = (index, id) => {
    let assessments = [...this.state.assessments];
    axiosPost(URL.getYearLevelByAssessment, { assessmentId: id }, (resp) => {
      if (resp.status === 200) {
        let yearLevels = resp.data.data;

        assessments[index].yearLevels = yearLevels;
        this.setState({ assessments }, () => {
          this.handleCollapseToggle(index);
        });
      }
    });
  };

  handleCollapseToggle = (i) => {
    let assessments = [...this.state.assessments];
    assessments.forEach((assessment, idx) => {
      if (i === idx) {
        assessment.isOpen = !assessment.isOpen;
      } else {
        assessment.isOpen = false;
      }
    });
    this.setState({ assessments });
  };

  toggleGeneralAssessmentSettingModal = (
    e,
    id,
    assessmentName,
    educationLevelId,
    educationLevelDivisionId,
    yearLevelId,
    classroomId,
    className,
    sectionName
  ) => {
    this.setState({
      generalAssessmentSettingModal: !this.state.generalAssessmentSettingModal,
    });
    let idObject = { ...this.state.idObject };
    idObject.educationLevelId = educationLevelId;
    idObject.educationLevelDivisionId = educationLevelDivisionId;
    idObject.yearLevelId = yearLevelId;
    idObject.classroomId = classroomId;
    idObject.assessmentId = id;

    this.setState({ idObject });
    this.setState({ assessmentName, className, sectionName });
  };

  toggleSubjectSettingAssessmentModal = (
    e,
    id,
    assessmentName,
    educationLevelId,
    educationLevelDivisionId,
    yearLevelId,
    classroomId,
    className,
    sectionName,
    subjectConfigurationEditable
  ) => {
    this.setState({
      subjectSettingAssessmentModal: !this.state.subjectSettingAssessmentModal,
    });
    let idObject = { ...this.state.idObject };
    idObject.educationLevelId = educationLevelId;
    idObject.educationLevelDivisionId = educationLevelDivisionId;
    idObject.yearLevelId = yearLevelId;
    idObject.classroomId = classroomId;
    idObject.assessmentId = id;

    this.setState({ idObject });

    this.setState({
      assessmentName,
      className,
      sectionName,
      subjectConfigurationEditable,
    });
  };

  toggleDetailModal = (
    e,
    id,
    educationLevelId,
    educationLevelDivisionId,
    yearLevelId,
    classroomId,
    subjectConfig,
    generalConfig
  ) => {
    this.setState({
      subjectSettingDetailModal: !this.state.subjectSettingDetailModal,
    });
    let idObject = { ...this.state.idObject };
    idObject.educationLevelId = educationLevelId;
    idObject.educationLevelDivisionId = educationLevelDivisionId;
    idObject.yearLevelId = yearLevelId;
    idObject.classroomId = classroomId;
    idObject.assessmentId = id;

    this.setState({ idObject, subjectConfig, generalConfig });
  };

  checkAlreadyConfiguredOrNot = (
    e,
    id,
    assessmentName,
    educationLevelId,
    educationLevelDivisionId,
    yearLevelId,
    classroomId,
    className,
    sectionName
  ) => {
    let param = {
      assessmentId: id,
      yearLevelId: yearLevelId,
      classroomId: classroomId,
    };

    axiosPost(URL.generalAssessmentSelect, param, (response) => {
      if (
        response.data.data.assessmentGeneralCommentConfigurations.length !==
          0 ||
        response.data.data.assessmentGeneralCustomAssessmentConfigurations
          .length !== 0
      ) {
        swal("Already Configured");
        //this.setState({ generalAssessmentSettingModal: false });
      } else {
        this.toggleGeneralAssessmentSettingModal(
          e,
          id,
          assessmentName,
          educationLevelId,
          educationLevelDivisionId,
          yearLevelId,
          classroomId,
          className,
          sectionName
        );
      }
    });
  };

  //Update and Cancel Assessment
  updateAssessment = (e, assessment) => {
    if (assessment !== undefined) {
      let data = {
        id: assessment.id,
      };
      axiosPost(URL.getAssessmentConfig, data, (response) => {
        if (response.status === 200) {
          this.setState(
            {
              updateData: assessment,
              updateConfig: response.data.data,
            },
            function () {
              axiosPost(
                URL.getYearLevelByAssessment,
                { assessmentId: assessment.id },
                (resp) => {
                  if (resp.status === 200) {
                    let yearLevels = resp.data.data;
                    let updateData = this.state.updateData;
                    updateData.yearLevels = yearLevels;
                    this.setState({
                      updateData,
                      updateModal: !this.state.updateModal,
                    });
                  }
                }
              );
            }
          );
        }
      });
    } else {
      this.setState({ updateModal: !this.state.updateModal });
    }
  };

  updateAssessmentBody = () => {
    return (
      <>
      <CreateAssessments
        code={this.props.code}
        updateData={this.state.updateData}
        updateConfig={this.state.updateConfig}
        updateAssessment={this.updateAssessment}
        assessmentOffset={this.state.assessmentOffset}
        assessmentRowCountLimit={this.state.assessmentRowCountLimit}
        name={this.props.name}
        edit={true}
        ></CreateAssessments>
        </>
    );
  };

  cancelAssessment = (e, id, value) => {
    let data = {
      id: id,
      isActive: value,
    };
    axiosPost(URL.cancelAssessment, data, (response) => {
      if (response.status === 200) {
        swal({
          title: "Success",
          text: value === true ? "Assessment enabled" : "Assessment Cancelled",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
        draggable();
        this.props.getAssessments(
          this.props.code,
          this.state.assessmentOffset,
          this.state.assessmentRowCountLimit
        );
      }
    });
  };

  //Pagination

  handlePreviousPage = () => {
    if (this.state.assessmentCurrentPage !== 1) {
      this.reRenderAssessmentList(this.state.assessmentCurrentPage - 1);
      this.setState((prevState) => ({
        assessmentCurrentPage: prevState.assessmentCurrentPage - 1,
      }));
    }
  };

  handleNextPage = () => {
    let nextPage = this.state.assessmentCurrentPage + 1;
    if (
      nextPage >
      Math.ceil(
        this.state.assessmentTotalRecords / this.state.assessmentRowCountLimit
      )
    )
      return;
    else {
      this.reRenderAssessmentList(nextPage);
      this.setState({ assessmentCurrentPage: nextPage });
    }
  };

  handlePageChange = (page) => {
    this.reRenderAssessmentList(page);
    this.setState({ assessmentCurrentPage: page });
  };

  reRenderAssessmentList = (page) => {
    let offset =
      page * parseInt(this.state.assessmentRowCountLimit) -
      parseInt(this.state.assessmentRowCountLimit);
    this.setState({ assessmentOffset: offset }, function () {
      this.props.getAssessments(
        this.props.code,
        offset,
        this.state.assessmentRowCountLimit
      );
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">
          List Assessment
          {checkWidgetAdminOrNot(this.props.name) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <div className="tt-newListBox">
          {this.state.assessments ? (
            this.state.assessments.length > 0 ? (
              this.state.assessments.map((assessment, i) => (
                <div className="card mb-2" key={i}>
                  <div
                    className="card-header tt-group-header mb-0"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.assessmentData(i, assessment.id);
                    }}
                  >
                    <div className="row">
                      <div className="col">
                        {assessment.assessmentName} -{" "}
                        {assessment.assessmentTypeName}{" "}
                        {assessment.isDeleted ? "( Canceled )" : ""}
                      </div>
                      {assessment.editable ? (
                        <div className="col text-right">
                          {this.state.permissionEditAssessment ? (
                            <button
                              className="tt-button tt-button-primary mr-3"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.updateAssessment(e, assessment);
                              }}
                              disabled={assessment.isDeleted ? true : false}
                            >
                              Update
                            </button>
                          ) : null}
                          {this.state.permissionCancelAssessment ? (
                            <button
                              className="tt-button tt-button-primary"
                              style={{ marginRight: "45px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                assessment.isDeleted
                                  ? this.cancelAssessment(
                                      e,
                                      assessment.id,
                                      true
                                    )
                                  : this.cancelAssessment(
                                      e,
                                      assessment.id,
                                      false
                                    );
                              }}
                            >
                              {assessment.isDeleted
                                ? "Enable Assessment"
                                : "Cancel Assessment"}
                            </button>
                          ) : null}
                        </div>
                      ) : null}
                      {!assessment.isDeleted ? (
                        <span
                          style={{
                            fontSize: "20px",
                            position: "absolute",
                            top: "6px",
                            right: "15px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={assessment.isOpen ? faAngleUp : faAngleDown}
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {assessment.isDeleted ? null : (
                    <Collapse isOpen={assessment.isOpen}>
                      <div className="card-body">
                        {assessment.yearLevels &&
                        assessment.yearLevels.length > 0 ? (
                          assessment.yearLevels.map((yearLevel, i) => (
                            <div
                              className={
                                assessment.yearLevels.length - 1 === i
                                  ? "card"
                                  : "card mb-4"
                              }
                            >
                              <div className="card-body">
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th
                                        className="tt-group-header"
                                        colSpan="4"
                                      >
                                        Class {yearLevel.name}
                                      </th>
                                    </tr>
                                  </thead>
                                  <thead>
                                    <th>Section</th>
                                    <th>Period</th>
                                    {this.state.permissionGeneralConfig ||
                                    this.state.permissionSubjectConfig ? (
                                      <>
                                        <th>Setting</th>
                                        <th>Options</th>
                                      </>
                                    ) : null}
                                  </thead>
                                  <tbody>
                                    {yearLevel.classrooms.length > 0 ? (
                                      yearLevel.classrooms.map((classroom) => (
                                        <tr>
                                          <td>{classroom.name}</td>
                                          <td>
                                            {classroom.assessmentFromDate &&
                                            classroom.assessmentToDate ? (
                                              `${formatDate(
                                                classroom.assessmentFromDate
                                              )} - ${formatDate(
                                                classroom.assessmentToDate
                                              )}`
                                            ) : (
                                              <i>No period for now.</i>
                                            )}
                                          </td>
                                          {this.state.permissionGeneralConfig ||
                                          this.state.permissionSubjectConfig ? (
                                            <td>
                                              {this.state
                                                .permissionGeneralConfig &&
                                              classroom.hasAssessmentGeneralConfiguration ? (
                                                <button
                                                  className="tt-button tt-button-primary mr-3"
                                                  onClick={(e) => {
                                                    this.toggleGeneralAssessmentSettingModal(
                                                      e,
                                                      assessment.id,
                                                      assessment.assessmentName,
                                                      assessment.educationLevelId,
                                                      assessment.educationLevelDivisionId,
                                                      yearLevel.id,
                                                      classroom.id,
                                                      yearLevel.name,
                                                      classroom.name
                                                    );
                                                  }}
                                                >
                                                  {"General Assessment"}
                                                </button>
                                              ) : null}
                                              {this.state
                                                .permissionSubjectConfig &&
                                              classroom.hasAssessmentSubjectConfiguration ? (
                                                <button
                                                  className="tt-button tt-button-primary"
                                                  onClick={(e) =>
                                                    this.toggleSubjectSettingAssessmentModal(
                                                      e,
                                                      assessment.id,
                                                      assessment.assessmentName,
                                                      assessment.educationLevelId,
                                                      assessment.educationLevelDivisionId,
                                                      yearLevel.id,
                                                      classroom.id,
                                                      yearLevel.name,
                                                      classroom.name,
                                                      classroom.subjectConfigurationEditable
                                                    )
                                                  }
                                                >
                                                  {"Subject Assessment"}
                                                </button>
                                              ) : null}
                                            </td>
                                          ) : null}
                                          <td>
                                            <button
                                              className="tt-button tt-button-primary"
                                              onClick={(e) =>
                                                this.toggleDetailModal(
                                                  e,
                                                  assessment.id,
                                                  assessment.educationLevelId,
                                                  assessment.educationLevelDivisionId,
                                                  yearLevel.id,
                                                  classroom.id,
                                                  classroom.hasAssessmentSubjectConfiguration,
                                                  classroom.hasAssessmentGeneralConfiguration
                                                )
                                              }
                                            >
                                              Details
                                            </button>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={4}>No data available</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))
                        ) : this.props.assessmentListSpinner ? (
                          <div className="text-center p-4">
                            <Spinner color="primary"></Spinner>
                            <br></br>Loading Data...
                          </div>
                        ) : (
                          <div className="text-center">No records found.</div>
                        )}
                      </div>
                    </Collapse>
                  )}
                </div>
              ))
            ) : this.props.assessmentListSpinner ? (
              <div className="text-center">
                <Spinner color="primary"></Spinner>
                <br></br>Loading Data...
              </div>
            ) : (
              <div className="text-center">No records found.</div>
            )
          ) : (
            <div className="text-center">No records found.</div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemsCount={this.state.assessmentTotalRecords}
              pageSize={this.state.assessmentRowCountLimit}
              currentPage={this.state.assessmentCurrentPage}
              onPageChange={this.handlePageChange}
              nextPage={this.handleNextPage}
              previousPage={this.handlePreviousPage}
            ></Pagination>
          </div>
        </div>

        <ModalWindow
          fullWidth={true}
          modal={this.state.subjectSettingAssessmentModal}
          toggleModal={this.toggleSubjectSettingAssessmentModal}
          modalBody={
            <SubjectAssessmentSetting
              code={this.props.code}
              subjectParams={this.state.idObject}
              assessmentName={this.state.assessmentName}
              sectionName={this.state.sectionName}
              className={this.state.className}
              toggleSubjectSettingAssessmentModal={
                this.toggleSubjectSettingAssessmentModal
              }
              subjectConfigurationEditable={
                this.state.subjectConfigurationEditable
              }
            />
          }
          modalHeader={"Subject Assessment Settings"}
        ></ModalWindow>
        <ModalWindow
          fullWidth={true}
          modal={this.state.generalAssessmentSettingModal}
          toggleModal={this.toggleGeneralAssessmentSettingModal}
          modalBody={
            <GeneralAssessmentSettings
              code={this.props.code}
              generalParams={this.state.idObject}
              assessmentName={this.state.assessmentName}
              sectionName={this.state.sectionName}
              className={this.state.className}
              toggleGeneralAssessmentSettingModal={
                this.toggleGeneralAssessmentSettingModal
              }
            />
          }
          modalHeader={"General Assessment Settings"}
        />
        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName={this.props.name}
              moduleName="Recording Setup"
              header="List Assessment"
              activityName="select-assessment"
            />
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.updateModal}
          toggleModal={this.updateAssessment}
          modalHeader="Edit Assessment"
          modalBody={this.updateAssessmentBody()}
          fullWidth={true}
        ></ModalWindow>
        <ModalWindow
          modal={this.state.subjectSettingDetailModal}
          fullWidth={true}
          toggleModal={this.toggleDetailModal}
          modalHeader="Assessment Detail"
          modalBody={
            <SubjectAssessmentDetails
              subjectParams={this.state.idObject}
              subjectConfig={this.state.subjectConfig}
              generalConfig={this.state.generalConfig}
            />
          }
        />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  assessments: state.primaryArr.assessments,
  assessmentListSpinner: state.primaryArr.assessmentListSpinner,
  assessmentTotalRecords: state.primaryArr.assessmentTotalRecords,
});

const mapActionsToProps = {
  getAssessments,
};

export default connect(mapStateToProps, mapActionsToProps)(Assessments);
