import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import makeAnimated from "react-select/animated";
import Slider from "react-slick";
import {
  faChalkboard,
  faGraduationCap,
  faClipboard,
  faHandshake,
  faClock,
  faVideo,
  faLaptopCode,
  faUsersCog,
  faBookReader,
  faBookOpen,
  faFileInvoiceDollar,
  faUserGraduate,
  faChalkboardTeacher,
  faClipboardList,
  faClipboardCheck,
  faCertificate,
  faIdCardAlt,
  faFile,
  faChartLine,
  faBellSlash,
  faBell,
  faComment,
  faSearch,
  faSchoolCircleCheck,
  faBookOpenReader,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/scss/widgetArea.scss";
import ModalWindow from "../UI/ModalWindow";
import Container from "./WidgetModal/Container";
import CreateYearLevel from "../AcademicAdministration/SchoolGrouping/YearLevel/CreateYearLevel";
import AssessmentRecordSetup from "../AssessmentRecordReport/AssessmentRecord/AssessmentRecordSetup";
import SubjectAreas from "../AssessmentRecordReport/SubjectSetups/Subject Area/SubjectAreas";
import Facilities from "../AcademicAdministration/School/Facilities/Facilities";
import Subjects from "../AssessmentRecordReport/SubjectSetups/Subjects";
import ListYearLevel from "../AcademicAdministration/SchoolGrouping/YearLevel/ListYearLevel";
import Departments from "../AcademicAdministration/School/Departments/Departments";
import Assessment from "../AcademicAdministration/Assessment/Assessment";
import ListEducationLevel from "../AcademicAdministration/SchoolGrouping/EducationLevel/ListEducationLevel";
import CalendarWidget from "../AcademicAdministration/Calendar/CalendarWidget";
import Information from "../AcademicAdministration/School/Information/Information";
import CreateAssessment from "../AssessmentRecordReport/AssessmentRecord/CreateAssessment/CreateAssessments";
import ListAssessmentScale from "../AssessmentRecordReport/AssessmentRecord/ListAssessmentScale/ListAssessmentScale";
import CreateClassroom from "../AcademicAdministration/SchoolGrouping/Classroom/CreateClassroom";
import NewRecruitmentConfiguration from "../Recruitment/Academic/NewRecruitmentConfiguration";
import CreateCourseSyllabus from "../CoursePlan/CreateCourseSyllabus";
import ListCourseSyllabus from "../CoursePlan/ListCourseSyllabus";
import {
  hrrCode,
  userRole,
  userRoleCode,
  checkRolePermissions,
  checkSubsTeacherPermissions,
  URL,
  checkWidgetAdminOrNot,
} from "../../utils/Constants";
import RecruitmentForm from "../Recruitment/Recruitment/RecruitmentForm";
import AdmissionConfiguration from "../Admission/AdmissionConfiguration";
import AdmissionForm from "../Admission/Admission/AdmissionForm";
import GeneralAssessmentList from "../AssessmentRecordReport/AssessmentRecord/GeneralAssessmentSettings/GeneralAssessmentList";
import SubjectAssessment from "../AssessmentRecordReport/Reports/SubjectAssessment/SubjectAssessment";
import Groups from "../AcademicAdministration/Groups/Groups";
import GroupLists from "../AcademicAdministration/Groups/GroupLists";
import ReportGenerator from "../AssessmentRecordReport/Reports/ReportGenerator/ReportGenerator";
import ReportConfiguration from "../AssessmentRecordReport/ReportingConfiguration/ReportingConfiguration";
import ReportingConfigurationList from "../AssessmentRecordReport/ReportingConfiguration/ReportingConfigurationList";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import Attendance from "../Attendance/Attendance";
import AttendanceHistory from "../Attendance/AttendanceHistory";
import Assessments from "../AssessmentRecordReport/ReportingSetup/ListAssessment/Assessments";
import CreateNewEducationLevel from "../AcademicAdministration/SchoolGrouping/EducationLevel/CreateNewEducationLevel";
import ClassroomList from "../AcademicAdministration/SchoolGrouping/Classroom/ClassroomList";
import StudentClassroomOptionalSubject from "../AssessmentRecordReport/SubjectSetups/StudentClassroomOptionalSubject/StudentClassroomOptionalSubject";
import StudentClassroomOptionalSubjectList from "../AssessmentRecordReport/SubjectSetups/StudentClassroomOptionalSubject/StudenetClassroomOptionalSubjectList";
import { axiosPost } from "../../utils/AxiosApi";
import { displaySuccessAlert } from "../../utils/Utils";
import { Spinner } from "reactstrap";
import AdmissionList from "../Admission/AdmissionList/AdmissionList";
import Staff from "../AcademicAdministration/EducationCommunity/Staff/Staff";
import AcademicYears from "../AcademicAdministration/AcademicYears/AcademicYears";
import AcademicYearsList from "../AcademicAdministration/AcademicYears/AcademicYearsList";
import VideoConferencing from "../VideoConferencing/VideoConferencing";
import CreateMCQ from "../MCQ/CreateMCQ";
import CreateFlashCards from "../FlashCards/CreateFlashCards";
import ListFlashCards from "../FlashCards/ListFlashCards";
import ListMCQ from "../MCQ/ListMCQ/ListMCQ";
import UserSetting from "../UserSetting/UserSetting";
import ListLMS from "../LearningManagementSystem/ListLMS";
import CreateLMS from "../LearningManagementSystem/CreateLMS";
import { cloneDeep } from "lodash";
import ListCourseModule from "../LearningManagementSystem/LMSModule/ListCourseModule";
import CreateSubjectAssignment from "../Assignment/CreateSubjectAssignment";
import ListSubjectAssignment from "../Assignment/ListSubjectAssignment";
import Category from "../Accounting/Catagory";
import FeeConfiguration from "../Accounting/FeeConfiguration";
import Payment from "../Accounting/Payment";
import CreateClassRoutine from "../ClassRoutine/CreateClassRoutine";
import ListClassRoutine from "../ClassRoutine/ListClassRoutine";
import BillGeneration from "../Accounting/BillGeneration";
import InventoryCategory from "../Accounting/Inventory/InventoryCategory";
import InventoryItems from "../Accounting/Inventory/InventoryItems";
import AssignInventoryItems from "../Accounting/Inventory/AssignInventoryItems";
import StudentInventory from "../Accounting/Inventory/StudentInventory";
import CalendarEventType from "../AcademicAdministration/Calendar/CalenderEventType";
import AttendanceForStudent from "../../components/Attendance/AttendanceForStudent";
import DisplayAllCalendar from "../AcademicAdministration/Calendar/DisplayAllCalendar";
import AddDesignation from "../AcademicAdministration/School/Designation/AddDesignation";
import Planner from "../Planner/Planner";
import ListPlanner from "../Planner/ListPlanner";
import Worksheet from "../Worksheets/Worksheet";
import WorksheetTemplate from "../Worksheets/WorksheetTemplate";
import CreateExamRoutine from "../ExamRoutine/CreateExamRoutine";
import ListExamRoutine from "../ExamRoutine/ListExamRoutine";
import ViewExamRoutine from "../ExamRoutineList/ViewExamRoutine";
import DraftBills from "../Accounting/DraftBills";
import PaymentHistory from "../Accounting/PaymentHistory";
import SystemExamConfiguration from "../ExamModule/SystemExamConfiguration";
import SystemExamAssessment from "../ExamModule/SystemExamAssessment";
import ExamForm from "../ExamModule/ExamForm";
import ARRCoursePlan from "../CoursePlan/ARRCoursePlan";
import SchoolLMS from "../SchoolLMS/SchoolLMS";
import ListSchoolLMS from "../SchoolLMS/ListSchoolLMS";
import Exammodule from "../ExamModule/Exammodule";
// import ObjectiveExamForm from "../ExamModule/ObjectiveExamForm";
// import ObjectiveExamList from "../ExamModule/ObjectiveExamList";
// import SubjectiveExamForm from "../ExamModule/SubjectiveExamForm";
// import SubjectiveExamList from "../ExamModule/SubjectiveExamList";
import CreateCards from "../Cards/CreateCards";
import ListCards from "../Cards/ListCards";
import LeaveForStudent from "../Attendance/LeaveForStudent";
import LeaveForStaff from "../Attendance/LeaveForStaff";
import MailConfiguration from "../Attendance/MailConfiguration";
import StudentLeaveList from "../Attendance/StudentLeaveList";
import StaffLeaveList from "../Attendance/StaffLeaveList";
import MemoryGame from "../MemoryGame/MainModule";
import PerformanceAnalysis from "../PerformanceAnalysis/PerformanceAnalysis";
// import LeaveRequests from "../Attendance/LeaveRequests";
import ListReminder from "../Reminders/ListReminder";
import Reminder from "../Reminders/Reminder";
import Footer from "./Footer";
import ELibrary from "../ELibrary/ELibrary";
import Dictionary from "../Dictionary/Dictionary";
import Feedback from "../Feedbacks/Feedback";
import ListFeedback from "../Feedbacks/ListFeedback";
import { Link } from "react-router-dom";
import TechnicalSchool from "../TechnicalSchools/TechnicalSchool";
import TeacherMaterial from "../TeacherMaterial/TeacherMaterial";
import ImportRecruitment from "../Recruitment/Recruitment/ImportRecruitment";

// const AdmissionList = React.lazy(() =>
//   import("../Admission/AdmissionList/AdmissionList")
// );
// const Staff = React.lazy(() =>
//   import("../AcademicAdministration/EducationCommunity/Staff/Staff")
// );

const animatedComponents = makeAnimated();
const settings = {
  // dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 11,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};

class WidgetArea extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.slide = this.slide.bind(this);
  }

  slide(x) {
    let disabledAxis = document.getElementsByClassName("slick-disabled");

    if (x === 0) {
    } else {
      // if (disabledAxis.length === 0) {
      x > 0 ? this.slider.slickPrev() : this.slider.slickNext();
      // }
    }
  }
  // slide(x) {
  //   let disabledAxis = document.getElementsByClassName("slick-disabled");

  //   if (x === 0) {
  //     debugger;
  //   } else if (
  //     disabledAxis.length > 0 &&
  //     disabledAxis[0].innerHTML === " Next" &&
  //     x > 0
  //   ) {
  //     debugger;
  //     x > 0 ? this.slider.slickPrev() : this.slider.slickNext();
  //   } else {
  //     debugger;
  //     x > 0 ? this.slider.slickPrev() : this.slider.slickNext();
  //   }
  // }

  componentDidUpdate() {
    let widgetDiv = document.getElementById("footer01");
    if (widgetDiv) {
      widgetDiv.addEventListener("wheel", (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.wheelDelta !== 0) {
          this.slide(e.wheelDelta);
        } else {
          this.slide(e.wheelDeltaX);
        }
      });
    }
  }

  state = {
    isLoading: null,
    modal: false,
    modalTitle: "",
    newApps: [],
    widgetId: 0,
    widgets: [
      {
        icon: faBookOpenReader,
        name: "Reading Materials",
        contents: [
          {
            name: "Reading Materials",
            code: "reading-materials",
            tabs: [
              {
                name: "Reading Materials",
                code: "reading-materials",
                content: <ELibrary schoolProfile={true} />,
              },
              {
                name: "Dictionary",
                code: "reading-materials",
                content: <Dictionary />,
              },
            ],
          },
          {
            name: "Curriculum",
            code: "school-curriculum",
            tabs: [
              {
                name: "Create Curriculum",
                code: "create-curriculum",
                content: <SchoolLMS lms={"curriculum"} />,
              },
              {
                name: "List Curriculum",
                code: "list-curriculum",
                content: <ListSchoolLMS lms={"curriculum"} />,
              },
            ],
          },
          {
            name: "Textbooks",
            code: "school-textbooks",
            tabs: [
              {
                name: "Create Text Books",
                code: "create-text-books",
                content: <SchoolLMS lms={"textbooks"} />,
              },
              {
                name: "List Text Books",
                code: "list-text-books",
                content: <ListSchoolLMS lms={"textbooks"} />,
              },
            ],
          },
          {
            name: "Question bank",
            code: "school-question-bank",
            tabs: [
              {
                name: "Create Question bank",
                code: "create-question-bank",
                content: <SchoolLMS lms={"question-bank"} />,
              },
              {
                name: "List Question Bank",
                code: "list-question-bank",
                content: <ListSchoolLMS lms={"question-bank"} />,
              },
            ],
          },
          {
            name: "Audio Visual Resources",
            code: "school-av-resources",
            tabs: [
              {
                name: "Create Audio Visual Resources",
                code: "create-audio-visual",
                content: <SchoolLMS lms={"av-resource"} />,
              },
              {
                name: "List Audio Visual Resources",
                code: "list-audio-visual",
                content: <ListSchoolLMS lms={"av-resource"} />,
              },
            ],
          },
          {
            name: "Sign Language Resources",
            code: "sign-language-resources",
            tabs: [
              {
                name: "Create Sign Language Resources",
                code: "create-sign-language",
                content: <SchoolLMS lms={"sign-language"} />,
              },
              {
                name: "List Sign Language Resources",
                code: "list-sign-language",
                content: <ListSchoolLMS lms={"sign-language"} />,
              },
            ],
          },
        ],
      },
      {
        icon: faGraduationCap,
        name: "Academic Administration",
        contents: [
          {
            name: "School",
            tabs: [
              {
                name: "Information",
                code: "select-school-information",
                content: <Information />,
              },
              {
                name: "Departments",
                code: "select-departments",
                content: <Departments />,
              },
              //supressed for now as no functionality
              // {
              //   name: "Campuses",
              //   code: "select-campuses",

              //   content: <StaticCampuses />,
              // },
              {
                name: "Facilities",
                code: "select-facilities",
                content: <Facilities />,
              },
              {
                name: " Designations",
                code: "add-designation",
                content: <AddDesignation />,
              },
            ],
          },
          {
            name: "Calendar",

            tabs: [
              {
                name: "View",
                code: "select-calendar",
                content: (
                  <CalendarWidget
                    assessmentModuleandEducationLevel={
                      this.props.assessmentModuleandEducationLevel
                    }
                  />
                ),
              },

              {
                name: "All Calendars",
                code: "display-all-calendar",
                content: <DisplayAllCalendar />,
              },

              //Insert EventType(Activity)

              {
                name: " Insert Event Type",
                code: "insert-event",
                content: <CalendarEventType />,
              },
            ],
          },
          {
            name: "Academic Years",
            tabs: [
              // {
              //   name: "Create",
              //   code: "insert-academic-years",
              //   content: <AcademicYears noNavbar={true} />,
              // },
              {
                name: "List",
                code: "select-academic-years",
                content: <AcademicYearsList />,
              },
            ],
          },
          {
            name: "School Groupings",
            tabs: [
              {
                name: "Create Education Level",
                code: "insert-education-level",
                // content: <CreateEducationLevel />
                content: (
                  <CreateNewEducationLevel
                    profilePeopleId={this.props.profilePeopleId}
                  />
                ),
              },
              {
                name: "List Education Level",
                code: "select-education-level",
                content: (
                  <ListEducationLevel
                    profilePeopleId={this.props.profilePeopleId}
                  />
                ),
              },
              {
                name: `Create ${
                  localStorage.getItem("yearLevel")
                    ? localStorage.getItem("yearLevel")
                    : "Grade"
                }`,
                code: "insert-grade",
                content: (
                  <CreateYearLevel
                    profilePeopleId={this.props.profilePeopleId}
                  />
                ),
              },
              {
                name: `List ${
                  localStorage.getItem("yearLevel")
                    ? localStorage.getItem("yearLevel")
                    : "Grade"
                }`,
                code: "select-grade",
                content: (
                  <ListYearLevel profilePeopleId={this.props.profilePeopleId} />
                ),
              },
              //   {
              //     name: "Assign year level",
              //     content: <AssignYearLevel />
              //   },
              {
                name: `Create ${
                  localStorage.getItem("classroom")
                    ? localStorage.getItem("classroom")
                    : "Section"
                }`,
                code: "insert-section",
                content: (
                  <CreateClassroom
                    profilePeopleId={this.props.profilePeopleId}
                  />
                ),
              },
              {
                name: `List ${
                  localStorage.getItem("classroom")
                    ? localStorage.getItem("classroom")
                    : "Section"
                }`,
                code: "select-section",
                content: <ClassroomList />,
                // content: <ListClassroom />
              },
              {
                name: "Create Group",
                code: "insert-group",
                content: <Groups />,
              },
              {
                name: "Add Group Members",
                code: "insert-group-members",
                content: <GroupLists members={true} />,
              },
              // {
              //   name: "Assign Students",
              //   content: <AssignStudents />
              // },
              // {
              //   name: "Assigned Students",
              //   content: <AssignedStudent />
              // }
            ],
          },
          {
            name: "Assessment Module",
            tabs: [
              {
                name: "Assign to Education Level",
                code: "assign-assessment-module",
                content: (
                  <Assessment
                    assign={true}
                    code="assign-assessment-module"
                    name="Assign To Education Level"
                  />
                ),
              },
              {
                name: "List Assessment Module",
                code: "list-assessment-module",
                content: (
                  <Assessment
                    assign={false}
                    code="list-assessment-module"
                    name="List Assessment Module"
                  />
                ),
              },
            ],
          },
          {
            name: "Education Community",
            tabs: [
              {
                name: "Student",
                code: "select-students",
                content: <AdmissionList fromEducationCommunity={true} />,
              },
              {
                name: "Staff",
                code: "select-staffs",
                content: <Staff fromEducationCommunity={true} />,
              },
            ],
          },

          // {
          //   name: "Groups",
          //   tabs: [
          //     {
          //       name: "Create Group",
          //       content: <Groups />
          //     },
          //     {
          //       name: "Add Group Members",
          //       content: <GroupLists members={true} />
          //     }
          //   ]
          // }
        ],
      },
      {
        icon: faUserGraduate,
        name: "Admission",
        contents: [
          {
            name: "Admission",
            tabs: [
              {
                name: "Admission Form",
                content: <AdmissionForm />,
                code: "insert-admission-form",
              },
              {
                name: "Admission List",
                content: <AdmissionList isVisible={true} />,
                code: "select-admission-list",
              },
            ],
          },
          {
            name: "Admission Configuration",
            tabs: [
              {
                name: "New Configuration",
                content: <AdmissionConfiguration />,
                code: "insert-admission-configuration",
              },
            ],
          },
          
        ],
      },
      {
        icon: faHandshake,
        name: "Recruitment",
        contents: [
          {
            name: "Recruitment",
            tabs: [
              {
                name: "Recruitment Form",
                code: "insert-recruitment",
                content: <RecruitmentForm />,
              },
              {
                name: "List Recruitment",
                code: "select-recruitment",
                content: (
                  <div>
                    <Staff isVisible={true} />
                  </div>
                ),
              },
              {
                name: "Import Recruitment Data",
                code: "import-recruitment",
                content: (
                  <div>
                    <ImportRecruitment />
                  </div>
                ),
              },
            ],
          },
          {
            name: "Recruitment Configuration",
            tabs: [
              {
                name: "New Configuration",
                code: "insert-recruitment-configuration",
                content: (
                  <div>
                    <NewRecruitmentConfiguration />
                  </div>
                ),
              },
            ],
          },
          
        ],
      },
      {
        icon: faChalkboard,
        name: "Class Routine",
        contents: [
          {
            name: "Class Routine",
            code: "CLASS_ROU",
            tabs: [
              {
                name: "Insert Class Routine",
                code: "insert-class-routine",
                content: <CreateClassRoutine />,
              },
              {
                name: "List Class Routine",
                code: "list-class-routine",
                content: <ListClassRoutine />,
              },
            ],
          },
          {
            name: "Exam Routine",
            code: "EXAM_ROU",
            tabs: [
              {
                name: "View Exam Routine",
                code: "view-exam-routine",
                content: <ViewExamRoutine />,
              },
            ],
          },
        ],
      },
      {
        icon: faClock,
        name: "Attendance",
        contents: [
          {
            name: "Student Attendance",
            code: "create-attendance",
            tabs: [
              {
                name: "Attendance",
                code: "insert-attendance",
                content: (
                  <div>
                    <Attendance />
                  </div>
                ),
              },
              {
                name: "Attendance Details",
                code: "select-attendance",
                content: <AttendanceHistory />,
              },
              {
                name: "Detail Summary ",
                code: "attendance-detail-summary",
                content: <AttendanceForStudent />,
              },
              {
                name: "Leave",
                code: "student-leave-request",
                content: <LeaveForStudent />,
              },
              {
                name: "Incoming Leave Requests",
                code: "incoming-student-leave",
                content: <StudentLeaveList />,
              },
            ],
          },
          {
            name: "Staff Attendance",
            code: "create-staff-attendance",
            tabs: [
              {
                name: "Leave",
                code: "staff-leave-request",
                content: <LeaveForStaff />,
              },

              {
                name: "Incoming Leave Requests",
                code: "incoming-staff-leave",
                content: <StaffLeaveList />,
              },

              {
                name: "Mail Configuration",
                code: "mail-config",
                content: <MailConfiguration />,
              },
            ],
          },
        ],
      },
      {
        icon: faVideo,
        name: "Video Conference",
        contents: [
          {
            name: "Conference",
            code: "conference",
            tabs: [
              {
                name: "Create",
                code: "create-video-conference",
                content: <VideoConferencing></VideoConferencing>,
              },
            ],
          },
        ],
      },
      {
        icon: faLaptopCode,
        name: "Game Based Learning",
        contents: [
          {
            name: "MCQ",
            code: "mcq",
            tabs: [
              {
                name: "Create MCQ",
                code: "create-mcq",
                content: <CreateMCQ></CreateMCQ>,
              },
              {
                name: "List MCQ",
                code: "list-mcq",
                content: <ListMCQ></ListMCQ>,
              },
            ],
          },
          {
            name: "Flash Cards",
            code: "flash-cards",
            tabs: [
              {
                name: "Create Flash Cards",
                code: "create-flash-cards",
                content: <CreateFlashCards title="Create Flash Card Set" />,
              },
              {
                name: "List Flash Cards",
                code: "list-flash-cards",
                content: <ListFlashCards></ListFlashCards>,
              },
            ],
          },
          {
            name: "Memory Game",
            code: "memory_game",
            tabs: [
              {
                name: "Play Game",
                code: "play_memory_game",
                content: <MemoryGame />,
              },
            ],
          },
        ],
      },
      {
        icon: faUsersCog,
        name: "User Setting",
        contents: [
          {
            name: "User List",
            code: "userList",
            tabs: [
              {
                name: "List",
                code: "list-user",
                content: <UserSetting></UserSetting>,
              },
            ],
          },
        ],
      },
      {
        icon: faChartLine,
        name: "Performance Analysis",
        contents: [],
        role: userRoleCode.roleStudent,
        content: <PerformanceAnalysis />,
      },

      {
        icon: faBookReader,
        name: "Assignment",
        contents: [
          {
            name: "Subject Assignment",
            code: "SUBASMT",
            tabs: [
              {
                name: "Create",
                code: "insert-subject-assignment",
                content: <CreateSubjectAssignment action="" />,
              },
              {
                name: "List",
                code: "list-subject-assignment",
                content: <ListSubjectAssignment />,
              },
            ],
          },
        ],
      },
      //Was created for demo purpose to show the LMS separtely for MBBS college before : OFF UNTIL FURTHER NEED
      // {
      //   icon: faChalkboardTeacher,
      //   name: "LMS",
      //   contents: [
      //     {
      //       name: "Programs",
      //       code: "programs",
      //       tabs: [
      //         {
      //           name: "Create Program",
      //           code: "create-program",
      //           content: <CreateLMS></CreateLMS>,
      //         },
      //         {
      //           name: "List Programs",
      //           code: "list-programs",
      //           content: <ListLMS></ListLMS>,
      //         },
      //       ],
      //     },
      //     {
      //       name: "Courses",
      //       code: "Courses",
      //       tabs: [
      //         {
      //           name: "List Courses",
      //           code: "list-courses",
      //           content: <ListCourseModule></ListCourseModule>,
      //         },
      //       ],
      //     },
      //     {
      //       name: "Classes",
      //       code: "Classes",
      //       tabs: [
      //         {
      //           name: "List Classes",
      //           code: "list-classes",
      //           content: <ListCourseModule type="classes"></ListCourseModule>,
      //         },
      //       ],
      //     },
      //   ],
      // },

      {
        icon: faBookOpen,
        name: "Course Plan",
        contents: [
          {
            name: "Course Syllabus",
            code: "COUR_SYLL",
            tabs: [
              {
                name: "Insert Course Syllabus",
                code: "insert-course-syllabus",
                content: <CreateCourseSyllabus />,
              },
              {
                name: "List Course Syllabus",
                code: "list-course-syllabus",
                content: <ListCourseSyllabus />,
              },
            ],
          },
        ],
      }, 
      {
        icon: faChalkboardTeacher,
        name: "Teacher Materials",
        contents: [
          {
            name: "Teacher Materials",
            code: "teacher-materials",
            tabs: [
              {
                name: "Teacher Material",
                code: "view-teacher-material",
                content: <TeacherMaterial />,
              },
            ],
          },
          {
            name: "Exam Routine",
            code: "EXAM_ROU",
            tabs: [
              {
                name: "View Exam Routine",
                code: "view-exam-routine",
                content: <ViewExamRoutine />,
              },
            ],
          },
        ],
      },

      // {
      //   icon: faFile,
      //   name: "Exam Routine",
      //   contents: [
      //     {
      //       name: "ExamRoutine",
      //       code: "exam_routine",
      //       tabs: [
      //         {
      //           name: "Create",
      //           code: "insert_exam_routine",
      //           content: <CreateExamRoutine />,
      //         },
      //         {
      //           name: "List",
      //           code: "list_exam_routine",
      //           content: <ListExamRoutine />,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        icon: faIdCardAlt,
        name: "Card and Certificates",
        contents: [
          {
            name: "Identity Card",
            code: "id-card",
            tabs: [
              {
                name: "Create Identity Card",
                code: "create_identity-card",
                content: <CreateCards />,
              },
              {
                name: "List Identity Card",
                code: "list-identity-card",
                content: <ListCards />,
              },
            ],
          },
        ],
      },
      {
        icon: faFileInvoiceDollar,
        name: "Account",
        contents: [
          {
            name: "Student Billing",
            code: "std-bill",
            tabs: [
              {
                name: "Fee Category",
                code: "fee-category",
                content: <Category />,
              },
              {
                name: "Fee Category Configuration",
                code: "fee-category-configuration",
                content: <FeeConfiguration />,
              },
              {
                name: "Bill Generation",
                code: "bill-generation",
                content: <BillGeneration />,
              },
              {
                name: "Bill Drafts",
                code: "bill-drafts",
                content: <DraftBills />,
              },
              {
                name: "Payment",
                code: "payment",
                content: <Payment />,
              },
              {
                name: "Payment History",
                code: "payment-history",
                content: <PaymentHistory />,
              },
            ],
          },
          {
            name: "Inventory",
            code: "inventory",
            tabs: [
              {
                name: "Inventory category",
                code: "inventory-category",
                content: <InventoryCategory />,
              },
              {
                name: "Inventory Items",
                code: "inventory-items",
                content: <InventoryItems />,
              },
              {
                name: "Assign Inventory Items",
                code: "assign-inventory-items",
                content: <AssignInventoryItems />,
              },
              {
                name: "Student Inventory",
                code: "student-inventory",
                content: <StudentInventory />,
              },
            ],
          },
        ],
      },
      {
        icon: faClipboardList,
        name: "Planner",
        contents: [
          {
            name: "Planner",
            code: "PLANNER",
            tabs: [
              {
                name: "Insert Planner",
                code: "insert-planner",
                content: <Planner />,
              },
              {
                name: "List Planner",
                code: "list-planner",
                content: <ListPlanner />,
              },
            ],
          },
        ],
      },
      {
        icon: faBell,
        name: "Reminder",
        contents: [
          {
            name: "Reminder",
            code: "REMINDER",
            tabs: [
              {
                name: "Create Reminder",
                code: "create-reminder",
                content: <Reminder />,
              },
              {
                name: "List Reminder",
                code: "list-reminder",
                content: <ListReminder />,
              },
            ],
          },
        ],
      },

      {
        icon: faSchoolCircleCheck,
        name: "Technical Schools",
        contents: [
          {
            name: "Technical School",
            code: "technical-school",
            tabs: [
              {
                name: "Technical Schools",
                code: "view-technical-school",
                content: <TechnicalSchool />,
              },
            ],
          },
        ],
      },

      {
        icon: faComment,
        name: "Feedbacks",
        contents: [
          {
            name: "Feedbacks",
            code: "feedbacks",
            tabs: [
              {
                name: "Feedback",
                code: "create-feedback",
                content: <Feedback />,
              },
              {
                name: "List Feedback",
                code: "list-feedback",
                content: <ListFeedback />,
              },
            ],
          },
        ],
      },

      {
        icon: faClipboardCheck,
        name: "WorkSheets",
        contents: [
          {
            name: "WorkSheets",
            code: "work_sheets",
            tabs: [
              {
                name: "Insert WorkSheets",
                code: "insert_work_sheets",
                content: <Worksheet />,
              },
              {
                name: "List WorkSheets",
                code: "list_work_sheets",
                content: <WorksheetTemplate />,
              },
            ],
          },
        ],
      },
      {
        icon: faCertificate,
        name: "Exam",
        contents: [
          {
            name: "Configurations",
            code: "exam-config",
            tabs: [
              {
                name: "System Exam",
                code: "config-system-exam",
                content: <SystemExamConfiguration />,
              },
              {
                name: "Question Paper",
                code: "config-question-paper",
                content: <SystemExamConfiguration />,
              },
            ],
          },
          {
            name: "Examination",
            code: "exam-exam",
            tabs: [
              {
                name: "Take Exam",
                code: "exam-take",
                content: <ExamForm />,
              },
              {
                name: "Check Exam",
                code: "exam-check",
                content: <SystemExamAssessment />,
              },
            ],
          },
        ],
      },
    ],

    templateARR: {
      icon: faClipboard,
      name: "ARR NAME",
      contents: [
        {
          name: "Subjects Setup",
          tabs: [
            {
              name: "Create Subject Area",
              code: "insert-subject-area",
              content: (
                <SubjectAreas create={true} code="ARR CODE" name="ARR NAME" />
              ),
            },
            {
              name: "List Subject Areas",
              code: "select-subject-area",
              content: (
                <SubjectAreas create={false} code="ARR CODE" name="ARR NAME" />
              ),
            },
            {
              name: "Create Subject",
              code: "insert-subject",
              content: (
                <Subjects
                  create={true}
                  code="ARR CODE"
                  name="ARR NAME"
                  profilePeopleId={this.props.profilePeopleId}
                />
              ),
            },
            {
              name: "List Subjects",
              code: "select-subject",
              content: (
                <Subjects create={false} code="ARR CODE" name="ARR NAME" />
              ),
            },
            {
              name: "Assign Student Optional Subjects",
              code: "insert-student-optional-subjects",
              content: (
                <StudentClassroomOptionalSubject
                  create={false}
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
            {
              name: "List Student Optional Subjects",
              code: "select-student-optional-subjects",
              content: (
                <StudentClassroomOptionalSubjectList
                  create={false}
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
            {
              name: "View Course Plan",
              code: "view_arr_course_plan",
              content: (
                <ARRCoursePlan
                  name="Higher Secondary ARR"
                  code={hrrCode.code}
                />
              ),
            },
          ],
        },
        {
          name: "Recording Setup",
          tabs: [
            {
              name: "Create Assessment Scale",
              code: "insert-assessment-scale",
              content: (
                <AssessmentRecordSetup
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
            {
              name: "List Assessment Scale",
              code: "select-assessment-scale",
              content: (
                <ListAssessmentScale
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
            {
              name: "Create Assessment",
              code: "insert-assessment",
              content: (
                <CreateAssessment
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
            {
              name: "List Assessment",
              code: "select-assessment",
              content: (
                <Assessments code={hrrCode.code} name="Higher Secondary ARR" />
              ),
            },
          ],
        },
        {
          name: "Reporting Setup",
          tabs: [
            {
              name: "Create Report Format",
              code: "insert-report-format",
              content: (
                <ReportConfiguration
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
            {
              name: "List Report Formats",
              code: "select-report-format",
              content: (
                <ReportingConfigurationList
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
          ],
        },
        {
          name: "Recording",
          tabs: [
            {
              name: "Subject Assessment",
              code: "select-subject-assessment",
              content: (
                <SubjectAssessment
                  code={hrrCode.code}
                  name="Higher Secondary ARR"
                />
              ),
            },
            {
              name: "General Assessment",
              code: "select-general-assessment",
              content: (
                <GeneralAssessmentList
                  name="Higher Secondary ARR"
                  code={hrrCode.code}
                />
              ),
            },
          ],
        },
        {
          name: "Reports",
          tabs: [
            {
              name: "Publish Assessment Report",
              code: "publish-assessment-report",
              content: (
                <ReportGenerator
                  name="Higher Secondary ARR"
                  code={hrrCode.code}
                />
              ),
            },
          ],
        },
      ],
    },
    minimize: false,
    apps: [
      { name: "Academic Administration", code: "ACA_ADMIN" },
      { name: "Admission", code: "ADM_ADMIN" },
      { name: "Recruitment", code: "REC_ADMIN" },
      { name: "Attendance", code: "ATD_ADMIN" },
      { name: "Profile Picture", code: "PP_ADMIN" },
      { name: "Academic Repository", code: "ACA_REPO_ADMIN" },
      { name: "Account", code: "ACC_ADMIN" },
      // { name: "Academic Repository", code: "ACA_REPO_ADMIN" },
      // { name: "Academic Repository", code: "ACA_REPO_ADMIN" },
      // { name: "Academic Repository", code: "ACA_REPO_ADMIN" },
    ],
    appAdminModal: false,
    assessmentModules: [],
    arrModules: [
      { component: SubjectAreas, create: true, name: "Create Subject Area" },
      { component: SubjectAreas, create: false, name: "List Subject Areas" },
      { component: Subjects, create: true, name: "Create Subject" },
      { component: Subjects, create: false, name: "List Subjects" },
      { component: ARRCoursePlan, create: false, name: "View Course Plan" },

      {
        component: StudentClassroomOptionalSubject,
        create: false,
        name: "Assign Student Optional Subjects",
      },
      {
        component: StudentClassroomOptionalSubjectList,
        create: false,
        name: "List Student Optional Subjects",
      },
      {
        component: AssessmentRecordSetup,
        name: "Create Assessment Scale",
      },
      {
        component: ListAssessmentScale,
        name: "List Assessment Scale",
      },
      {
        component: CreateAssessment,
        name: "Create Assessment",
      },
      { component: Assessments, name: "List Assessment" },
      {
        component: ReportConfiguration,
        name: "Create Report Format",
      },
      {
        component: ReportingConfigurationList,
        name: "List Report Formats",
      },
      {
        component: SubjectAssessment,
        name: "Subject Assessment",
      },
      {
        component: GeneralAssessmentList,
        name: "General Assessment",
      },
      {
        component: ReportGenerator,
        name: "Publish Assessment Report",
      },
    ],
    loadingWidgets: true,
    setWidget: false,
  };

  componentDidMount() {
    this.getAssessmentModules();
    this.getUsers();
    this.getAppAdmins();
  }

  /**
   * @author Saurav Sitaula
   * @description dynamically generate ARRs in
   * widgets based on assessment modules in system
   * added 12-24-2020
   */
  populateWidgetsByAssessmentModules = () => {
    let newWidgets = [...this.state.widgets],
      newApps = [...this.state.apps],
      insertIndex = 2;
    if (this.state.assessmentModules.length > 0) {
      for (let i = 0; i < this.state.assessmentModules.length; i++) {
        let aModule = this.state.assessmentModules[i];
        let newARRTemplate = cloneDeep(this.state.templateARR);
        newARRTemplate.name = aModule.name;
        let newAppsTemplate = {
          name: aModule.name,
          code: `${aModule.code}_ADMIN`,
        };
        newARRTemplate.contents.forEach((content) => {
          content.tabs.forEach((tab, tabIndex) => {
            const arrModuleIndex = this.state.arrModules.findIndex(
              (arrmodule) => arrmodule.name === tab.name
            );
            if (arrModuleIndex !== -1) {
              const arrmodule = this.state.arrModules[arrModuleIndex];
              if (arrmodule.create !== null && arrmodule.create !== undefined) {
                tab.content = (
                  <arrmodule.component
                    create={arrmodule.create}
                    code={aModule.code}
                    name={aModule.name}
                    profilePeopleId={this.props.profilePeopleId}
                  />
                );
              } else {
                tab.content = (
                  <arrmodule.component
                    code={aModule.code}
                    name={aModule.name}
                    profilePeopleId={this.props.profilePeopleId}
                  />
                );
              }
            }
          });
        });
        newWidgets.splice(insertIndex, 0, newARRTemplate);
        newApps.splice(insertIndex, 0, newAppsTemplate);
        insertIndex++;
      }
    }
    this.setState({ widgets: newWidgets, apps: newApps }, () => {
      this.renderWidgetsWithPermission();
    });
  };

  getAssessmentModules = () => {
    axiosPost(
      URL.getAssesmentModules,
      {},
      (response) => {
        if (response.status === 200) {
          this.setState({ assessmentModules: response.data.data }, () => {
            this.populateWidgetsByAssessmentModules();
          });
        } else {
          this.setState({ loadingWidgets: false });
          this.populateWidgetsByAssessmentModules();
        }
      },
      (err) => {
        this.setState({ loadingWidgets: false });
        this.populateWidgetsByAssessmentModules();
      }
    );
  };

  getUsers = () => {
    axiosPost(URL.getUserByRoleCode, { userRoleCode: "RL_STF" }, (response) => {
      if (response.status === 200) {
        let users = [];
        if (response.data.data && response.data.data.length > 0) {
          users = response.data.data.map((user) => {
            return {
              value: user.id,
              label: user.name,
            };
          });
        }
        this.setState({ users: users });
      }
    });
  };

  getAppAdmins = () => {
    let newApps = [...this.state.apps];
    axiosPost(
      URL.getUserSubRolesBySubRoleCode,
      this.state.apps.map(function (app) {
        return { subRoleCode: app.code };
      }),
      (response) => {
        newApps.forEach((newApp) => {
          newApp.userSubRoles = response.data.data;
          let newUserSubRoles = newApp.userSubRoles?.map((userSubRole) => {
            return {
              value: userSubRole.userId,
              label: userSubRole.name,
            };
          });
          newApp.userSubRoles = newUserSubRoles;
          newApp.userSubRolesForDelete = newUserSubRoles;
        });
      }
    );
    // });
  };

  /**
   * @author Saurav Sitaula
   * @description renders widgets,modules based on widgets,modules present in permission array
   */
  renderWidgetsWithPermission = () => {
    let newWidgets = [...this.state.widgets];
    this.state.widgets.forEach((widget) => {
      let bool =
        checkRolePermissions(widget.name, "widgets") ||
        checkSubsTeacherPermissions(widget.name, "widgets");
      if (bool === false) {
        let deleteId = newWidgets.findIndex(
          (newwidget) => newwidget.name === widget.name
        );
        deleteId !== -1 && newWidgets.splice(deleteId, 1);
      }
    });
    this.setState({ widgets: newWidgets }, () => {
      let newWidgetsForMod = [...this.state.widgets];
      newWidgetsForMod.forEach((widget, idx) => {
        widget.contents.forEach((content, contentId) => {
          let bool =
            checkRolePermissions(content.name, "modules", widget.name) ||
            checkSubsTeacherPermissions(content.name, "modules", widget.name);
          let deleteId = widget.contents.findIndex(
            (newContent) => newContent.name === content.name
          );
          let newContents = [...widget.contents];
          bool === false && deleteId !== -1 && newContents.splice(deleteId, 1);
          widget.contents = newContents;
        });
      });
      this.setState({ widgets: newWidgetsForMod }, () => {
        let newWidgetsForAct = [...this.state.widgets];
        newWidgetsForAct.forEach((widget) => {
          widget.contents.forEach((content) => {
            content.tabs.forEach((tab) => {
              let bool =
                checkRolePermissions(tab.code, "activity", widget.name) ||
                checkSubsTeacherPermissions(tab.code, "activity", widget.name);
              let deleteId = content.tabs.findIndex(
                (newTab) => newTab.code === tab.code
              );
              let newTabs = [...content.tabs];
              bool === false && deleteId !== -1 && newTabs.splice(deleteId, 1);
              content.tabs = newTabs;
            });
          });
        });
        this.setState({
          widgets: newWidgetsForAct,
          loadingWidgets: false,
          setWidget: true,
        });
      });
    });
  };

  top = () => {
    this.componentRef.scrollToTop = 0;
  };

  loadSpinner = (e, id) => {
    this.setState({ isLoading: true }, function () {
      this.widgetModal(e, id);
    });
  };

  widgetModal = (e, id) => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      function () {
        this.setState({ isLoading: false });
      }
    );
    if (id !== undefined) {
      this.setState({
        widgetId: id,
        modalTitle: this.state.widgets[id].name,
      });
    }
  };

  showContainer = () => {
    let id = this.state.widgetId;
    return (
      <div className="modules-container">
        <Container
          contents={
            this.state.widgets.length > 0 && this.state.widgets[id].contents
          }
          name={this.state.widgets.length > 0 && this.state.widgets[id].name}
          content={
            this.state.widgets.length > 0 &&
            this.state.widgets[id].contents.length === 0
              ? this.state.widgets[id].content
              : null
          }
        />
      </div>
    );
  };

  minimizeWidgetContainer = (e) => {
    this.setState({ minimize: !this.state.minimize });
  };

  /**
   * @author Saurav Sitaula
   * render widget apps for role student
   */
  renderWidgetStudent = () => {
    return this.state.widgets?.map((wid, idx) => {
      return this.state.student?.map((student) => {
        if (student === wid.name) {
          return (
            <div
              className="tt-widget-holder"
              key={idx}
              id={idx}
              onClick={(e) => this.widgetModal(e, idx)}
            >
              <div className="tt-widget-icon">
                <FontAwesomeIcon icon={wid.icon} />
              </div>
              <div className="tt-widget-title">
                {wid.name === "Academic Administration"
                  ? "The School"
                  : wid.name}
              </div>
            </div>
          );
        }
      });
    });
  };

  /**
   * @author Saurav Sitaula
   * render widget apps for role student contact
   */
  renderWidgetStudentContact = () => {
    return this.state.widgets?.map((wid, idx) => {
      return this.state.studentContact?.map((student) => {
        if (student === wid.name) {
          return (
            <div
              className="tt-widget-holder"
              key={idx}
              id={idx}
              onClick={(e) => this.widgetModal(e, idx)}
            >
              <div className="tt-widget-icon">
                <FontAwesomeIcon icon={wid.icon} />
              </div>
              <div className="tt-widget-title">
                {wid.name === "Academic Administration"
                  ? "The School"
                  : wid.name}
              </div>
            </div>
          );
        }
      });
    });
  };

  renderWidgetHomeRoomTeacher = () => {
    return this.state.widgets?.map((wid, idx) => {
      return this.state.homeRoomTeacher?.map((teacher) => {
        if (teacher === wid.name) {
          return (
            <div
              className="tt-widget-holder"
              key={idx}
              id={idx}
              onClick={(e) => this.widgetModal(e, idx)}
            >
              <div className="tt-widget-icon">
                <FontAwesomeIcon icon={wid.icon} />
              </div>
              <div className="tt-widget-title">{wid.name}</div>
            </div>
          );
        }
      });
    });
  };

  /**
   * @author Saurav Sitaula
   * render widget apps for role non-student and non-student contact
   */
  renderWidgets = () => {
    return this.state.widgets.map((wid, idx) => {
      return (
        <>
          {wid.name === "Performance Analysis" ? (
            userRole === userRoleCode.roleStudent ? (
              <div
                className="tt-widget-holder"
                key={idx}
                id={idx}
                onClick={(e) => this.loadSpinner(e, idx)}
              >
                <div className="tt-widget-icon">
                  <FontAwesomeIcon icon={wid.icon} />
                </div>
                <div className="tt-widget-title">
                  {(userRole === userRoleCode.roleStudent ||
                    userRole === userRoleCode.roleStudentContact) &&
                  wid.name === "Academic Administration"
                    ? "The School"
                    : wid.name}
                </div>
              </div>
            ) : null
          ) : (
            <div
              className="tt-widget-holder"
              key={idx}
              id={idx}
              onClick={(e) => this.loadSpinner(e, idx)}
            >
              <div className="tt-widget-icon">
                <FontAwesomeIcon icon={wid.icon} />
              </div>
              <div className="tt-widget-title">
                {(userRole === userRoleCode.roleStudent ||
                  userRole === userRoleCode.roleStudentContact) &&
                wid.name === "Academic Administration"
                  ? "The School"
                  : wid.name}
              </div>
            </div>
          )}
        </>
      );
    });
  };

  renderWidgetStaffs = () => {
    return this.state.widgets?.map((wid, idx) => {
      return this.state.staff?.map((nonStudent) => {
        if (nonStudent === wid.name) {
          return (
            <div
              className="tt-widget-holder"
              key={idx}
              id={idx}
              onClick={(e) => this.widgetModal(e, idx)}
            >
              <div className="tt-widget-icon">
                <FontAwesomeIcon icon={wid.icon} />
              </div>
              <div className="tt-widget-title">{wid.name}</div>
            </div>
          );
        }
      });
    });
  };
  toggleAppAdminModal = () => {
    // if (this.state.appAdminModal === false) {
    //   this.getAppAdmins();
    // } else {
    this.setState({ appAdminModal: !this.state.appAdminModal });
    this.getAppAdmins();
    // }
  };

  renderAppAdminModal = () => {
    return (
      <>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "75vh", overflow: "auto" }}
        >
          <div className="container-fluid">
            {this.state.apps?.map((app, index) => (
              <div className="row" key={index}>
                <div className="col-md-5 mt-2">
                  <strong>{app.name}</strong>
                </div>
                <div className="col-md-7 mt-2">
                  <Select
                    value={
                      this.state.newApps &&
                      this.state.newApps[index] &&
                      this.state.newApps[index].userSubRoles
                    }
                    closeMenuOnSelect={false}
                    placeholder={<div>select admins</div>}
                    components={animatedComponents}
                    isMulti
                    options={this.state.users}
                    name="users"
                    onChange={(users) => this.handleAppAdminChange(users, app)}
                    menuPlacement="auto"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col text-right">
              <button
                className="tt-button tt-button-primary mr-3"
                onClick={this.handleAppAdminSave}
              >
                Save
              </button>
              <button
                className="tt-button tt-button-primary"
                onClick={this.toggleAppAdminModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  handleAppAdminChange = (users, app) => {
    let newApps = [...this.state.apps];
    let index = newApps.findIndex((newapp) => newapp.name === app.name);
    if (index !== -1) {
      newApps[index].userSubRoles = users;
    }
    this.setState({ newApps: newApps });
  };

  handleAppAdminSave = () => {
    let data = [];
    this.state.newApps.forEach((newapp) => {
      if (newapp.userSubRoles !== null && newapp.userSubRoles !== undefined) {
        newapp.userSubRoles.forEach((role) => {
          data.push({
            userId: role.value,
            subRoleCode: newapp.code,
          });
        });
      } else {
        data.push({
          subRoleCode: newapp.code,
        });
      }
    });
    axiosPost(URL.insertUserSubRoles, data, (response) => {
      displaySuccessAlert(response);
      this.getAppAdmins();
    });
  };

  render() {
    return (
      <>
        {/* <div
          id="tt-widget-container"
          className={
            this.state.minimize
              ? "tt-widget-container minimize-group"
              : "tt-widget-container"
          }
          style={{ marginBottom: "5px" }}
        >
          <div className="tt-group-header">
            <div
              className="row"
              onDoubleClick={(e) => this.minimizeWidgetContainer(e)}
            >
              <div
                className="col"
                data-tip="Double click to expand and collapse this widget"
              >
                Apps
              </div>
              {checkWidgetAdminOrNot() === true ? (
                <div className="col">
                  <button
                    className="tt-button tt-button-primary float-right"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        appAdminModal: !this.state.appAdminModal,
                      });
                    }}
                  >
                    App Admin
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="tt-widget-box">
            {!this.state.loadingWidgets ? (
              this.renderWidgets()
            ) : (
              <Spinner color="primary" style={{ marginTop: "140px" }}></Spinner>
            )}
          </div>
          {this.state.isLoading ? (
            <div className="tt-widgetSpinner">
              <Spinner color="primary"></Spinner>
            </div>
          ) : null}
          <ReactTooltip globalEventOff="click" />
          <ModalWindow
            fullWidth={true}
            modal={this.state.modal}
            toggleModal={this.widgetModal}
            modalHeader={
              userRole === userRoleCode.roleStudent ||
              userRole === userRoleCode.roleStudentContact
                ? this.state.modalTitle === "Academic Administration"
                  ? "The School"
                  : this.state.modalTitle
                : this.state.modalTitle
            }
            modalBody={this.showContainer()}
            ref={this.componentRef}
          />
          <ModalWindow
            size="lg"
            modal={this.state.appAdminModal}
            toggleModal={this.toggleAppAdminModal}
            modalHeader={"App Administrator"}
            modalBody={this.renderAppAdminModal()}
          ></ModalWindow>
        </div> */}
        <div className="newWidgetHolder">
          <div className="footer-01" id="footer01">
            {this.state.setWidget ? (
              <Slider {...settings} ref={(slider) => (this.slider = slider)}>
                {this.state.widgets &&
                  this.state.widgets?.map((items, index) => {
                    if (items.name === "Performance Analysis") {
                      if (userRole === userRoleCode.roleStudent) {
                        return (
                          <div
                            className="tt-widget-holder-new"
                            key={index}
                            onClick={(e) => this.loadSpinner(e, index)}
                          >
                            <div className="tt-widget-icon">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={items.icon}
                                  className="tt-widget-fontAwesome-icon"
                                />
                                <div className="tt-widget-title">
                                  {(userRole === userRoleCode.roleStudent ||
                                    userRole ===
                                      userRoleCode.roleStudentContact) &&
                                  items.name === "Academic Administration"
                                    ? "The School"
                                    : items.name === "Reading Materials"
                                    ? "E-Library"
                                    : items.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    } else {
                      return (
                        <div
                          className="tt-widget-holder-new"
                          key={index}
                          onClick={(e) => this.loadSpinner(e, index)}
                        >
                          <div className="tt-widget-icon">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={items.icon}
                                className="tt-widget-fontAwesome-icon"
                              />

                              <div className="tt-widget-title">
                                {(userRole === userRoleCode.roleStudent ||
                                  userRole ===
                                    userRoleCode.roleStudentContact) &&
                                items.name === "Academic Administration"
                                  ? "The School"
                                  : items.name === "Reading Materials"
                                  ? "E-Library"
                                  : items.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </Slider>
            ) : (
              <div className="text-center mt-2">
                <Spinner />
              </div>
            )}
          </div>
          <div className="searchWidget">
            {/* <Link to="/search-imagine" className="newWidgetLink"> */}
            <Link
              to={{
                pathname: "/search-imagine",
                state: {
                  noNav: false,
                },
              }}
              className="newWidgetLink"
            >
              <div className="tt-widget-holder-new">
                <div className="tt-widget-icon">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="tt-widget-fontAwesome-icon"
                    />
                    <div className="tt-widget-title">Search</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <ModalWindow
          backdrop="static"
          keyboard={false}
          fullWidth={true}
          modal={this.state.modal}
          toggleModal={this.widgetModal}
          modalHeader={
            userRole === userRoleCode.roleStudent ||
            userRole === userRoleCode.roleStudentContact
              ? this.state.modalTitle === "Academic Administration"
                ? "The School"
                : this.state.modalTitle === "Reading Materials"
                ? "E-Library"
                : this.state.modalTitle
              : this.state.modalTitle === "Reading Materials"
              ? "E-Library"
              : this.state.modalTitle
          }
          modalBody={this.showContainer()}
          ref={this.componentRef}
        />
        <ModalWindow
          size="lg"
          modal={this.state.appAdminModal}
          toggleModal={this.toggleAppAdminModal}
          modalHeader={"App Administrator"}
          modalBody={this.renderAppAdminModal()}
        ></ModalWindow>
      </>
    );
  }
}

export default WidgetArea;
