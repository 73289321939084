import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import swal from "sweetalert";
import logo from "../../assets/images/logo/logo2.png";
import nepalLogo from "../../assets/images/logo/nepal_logo.png";
import CustomButton from "../../components/common/CustomButton";
import CustomSpinner from "../../components/common/CustomSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import {
    checkUserRole,
    cryptoSecretKey,
    loginUrl,
    reactServer,
    URL,
} from "../../utils/Constants";
import history from "../../utils/History";
import CryptoJS from "crypto-js";
import AsyncSelect from "react-select/async";
import OTPpage from "./OTPpage";
import ModalWindow from "../UI/ModalWindow";
import { displayErrorMessage, displaySuccessAlert } from "../../utils/Utils";
import imagineLogo from "../../assets/images/logo/logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class LoginPage extends Component {
    state = {
        email: "",
        password: "",
        passwordType: "password",
        showSpinner: false,
        schoolList: [],
        activeTab: "1",
        username: "",
        selectedSchool: null,
        OTPToggle: false,
        logoPreview: "",
        modal: false,
        userType: [],
        forgotUsername: "",
        forgotUserType: "",
        showPassword: false
    };

    getUserRoles = () => {
        axiosPost(URL.getUserRoleNoAuth, {}, (response) => {
            this.setState({ userType: response.data.data });
        });
    };

    handleChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });
        if (name === "school") {
            this.getSchoolsByName(value);
        }
    };

    handleLogin = (e) => {
        e.preventDefault();
        let params = {
            username: this.state.username,
            password: this.state.password,
        };

        if (this.state.username === "" && this.state.password === "") {
            swal({
                title: "Error",
                text: "Please input both username and password",
                allowOutsideClick: false,
                closeOnClickOutside: false,
            });
        } else if (this.state.username === "") {
            swal({
                title: "Error",
                text: "Please input your username",
                allowOutsideClick: false,
                closeOnClickOutside: false,
            });
        } else if (this.state.password === "") {
            swal({
                title: "Error",
                text: "Please input your password",
                allowOutsideClick: false,
                closeOnClickOutside: false,
            });
        } else {
            this.setState({ showSpinner: true }, function () {
                axiosPost(
                    loginUrl,
                    params,
                    (response) => {
                        if (response?.status === 200) {
                            this.loginResponseSetter(response);
                        }
                    },
                    (error) => {
                        let errorResponse = error.response ? error.response.data : error;
                        let errorTitle = errorResponse.error
                            ? errorResponse.error === "Internal Server Error"
                                ? "Unable to Login"
                                : errorResponse.error
                            : "Unable to Login";
                        swal({
                            title: errorTitle,
                            text: errorResponse.message
                                ? errorResponse.message
                                : "Could not connect to the server",
                        });

                        this.setState({ showSpinner: false });
                    }
                );
            });
        }
    };

    getWebsiteConfig = () => {
        axiosPost(URL.getWebsiteConfig, {}, (response) => {
            if (response.status === 200) {
                let data = response.data.data;
                if (data.length > 0) {
                    // setWebTitle(data[0].title);
                    // setDisclaimer(data[0].disclaimer);
                    // setFaviconPreview(data[0].faviconPath);
                    this.setState({
                        logoPreview: data[0].logoPath,
                    });
                }
            }
        });
    };

    componentDidMount() {
        this.getWebsiteConfig();
        this.getUserRoles();
    }

    handleInstitutionLogin = (e) => {
        e.preventDefault();
        let params = {
            username: this.state.username.trim(),
            password: this.state.password.trim(),
            schoolId: this.state.selectedSchool?.id,
        };
        if (
            this.state.username === "" &&
            this.state.password === "" &&
            this.state.selectedSchool === null
        ) {
            swal({
                title: "Error",
                text: "Please enter all the fields.",
                allowOutsideClick: false,
                closeOnClickOutside: false,
            });
        } else if (this.state.username === "") {
            swal({
                title: "Error",
                text: "Please input your username",
                allowOutsideClick: false,
                closeOnClickOutside: false,
            });
        } else if (this.state.password === "") {
            swal({
                title: "Error",
                text: "Please input your password",
                allowOutsideClick: false,
                closeOnClickOutside: false,
            });
        } else if (this.state.selectedSchool === null) {
            swal({
                title: "Error",
                text: "Please enter school code",
                allowOutsideClick: false,
                closeOnClickOutside: false,
            });
        } else {
            this.setState({ showSpinner: true }, function () {
                axiosPost(
                    loginUrl,
                    params,
                    (response) => {
                        if (response.status === 200) {
                            this.loginResponseSetter(response);
                        }
                    },
                    (error) => {
                        let errorResponse = error.response ? error.response.data : error;
                        let errorTitle = errorResponse.error
                            ? errorResponse.error === "Internal Server Error"
                                ? "Unable to Login"
                                : errorResponse.error
                            : "Unable to Login";
                        swal({
                            title: errorTitle,
                            text: errorResponse.message
                                ? errorResponse.message
                                : "Could not connect to the server",
                        });

                        this.setState({ showSpinner: false });
                    }
                );
            });
        }
    };

    // getSchoolsByName = (value) => {
    //   axiosPost(
    //     URL.getSchoolsByName,
    //     { name: value },
    //     (response) => {
    //       if (response.status === 200) {
    //         this.setState({ schoolList: response.data.data });
    //       }
    //     },
    //     (err) => {},
    //     true
    //   );
    // };

    loginResponseSetter = (response) => {
        let userRole = response.data.permissionWrapper;
        localStorage.setItem("roleName", userRole.roleName);
        localStorage.setItem(
            "permissions",
            CryptoJS.AES.encrypt(JSON.stringify(userRole.modules), cryptoSecretKey)
        );

        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("yearLevel", "Class");
        localStorage.setItem("classroom", "Classroom");
        localStorage.setItem("educationLevelDivision", "Term");

        localStorage.setItem("is2FAEnabled", response.data.is2FaEnabled);

        if (response.data.userEmail !== "") {
            localStorage.setItem("canUse2FA", true);
        } else {
            localStorage.setItem("canUse2FA", false);
        }

        // if (!response.data.is2FaEnabled) {

        localStorage.setItem("schoolSetup", true);
        // localStorage.setItem("schoolSetup", response.data.schoolSetup);
        localStorage.setItem(
            "educationCommunityGroupName",
            response.data.educationCommunityGroupName
        );
        localStorage.setItem("academicYears", true);
        localStorage.setItem("refreshToken", response.data.refresh_token);

        localStorage.setItem("isFirstLogin", response.data.isFirstLogin);

        localStorage.setItem(
            "widgets",
            CryptoJS.AES.encrypt(
                JSON.stringify(response.data.academicPermissionDto.widgets),
                "saurav_imagine_sys"
            )
        );
        response.data.substituteTeacherRolePermissions =
            response.data.substituteTeacherRolePermissions || null;
        localStorage.setItem(
            "substituteTeacherRolePermissions",
            CryptoJS.AES.encrypt(
                JSON.stringify(response.data.substituteTeacherRolePermissions),
                "saurav_imagine_sys"
            )
        );

        localStorage.setItem(
            "academicYearId",
            response.data.academicPermissionDto.academicYearId
        );
        localStorage.setItem(
            "subRoles",
            CryptoJS.AES.encrypt(
                JSON.stringify(response.data.subRoles),
                "saurav_imagine_sys"
            )
        );
        if (response.data.associatedUsers) {
            localStorage.setItem(
                "associatedUsers",
                CryptoJS.AES.encrypt(
                    JSON.stringify(response.data.associatedUsers),
                    "saurav_imagine_sys"
                )
            );
        }

        localStorage.setItem(
            "role",
            CryptoJS.AES.encrypt(
                response.data.userRoleCode,
                "saurav_imagine_sys"
            ).toString()
        );
        localStorage.setItem("peopleId", response.data.peopleId);
        localStorage.setItem("profilePeopleId", response.data.peopleId);
        localStorage.setItem("familyId", response.data.familyId);
        localStorage.setItem("mainUserId", response.data.userId);
        localStorage.setItem("schoolId", response.data.schoolId);

        //   localStorage.setItem(
        //     "isCoordinator",
        //     response.data.isCoordinator
        //   );
        //   localStorage.setItem(
        //     "isHomeRoomTeacher",
        //     response.data.isHomeRoomTeacher
        //   );
        if (this.state.rememberMe) {
            localStorage.setItem(
                "username",
                CryptoJS.AES.encrypt(
                    this.state.username,
                    "saurav_imagine_sys"
                ).toString()
            );
            localStorage.setItem(
                "password",
                CryptoJS.AES.encrypt(
                    this.state.password,
                    "saurav_imagine_sys"
                ).toString()
            );
        }
        //this.getRecruitmentIdPromise(response.data.peopleId);
        this.setState({
            showSpinner: false,
            OTPToggle: response.data.is2FaEnabled,
        });
        this.redirectTer(response);

        // if (response.data.userId == 1 || checkUserRole()) {
        //   history.push(`${process.env.PUBLIC_URL}/dashboard/main`);
        // } else if (response.data.userRoleCode === "RL_STC") {
        //   history.push(
        //     `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
        //   );
        // } else {
        //   response.data.schoolSetup
        //     ? response.data.academicYears
        //       ? history.push(`${process.env.PUBLIC_URL}/profile/`)
        //       : history.push(`${process.env.PUBLIC_URL}/academicYears`)
        //     : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
        // }
        // }
        // else {
        //   this.setState({
        //     responseLoginData: response.data,
        //     OTPToggle: true,
        //   });
        // }
    };

    redirectTer = (response) => {
        if (response.data.is2FaEnabled) {
            history.push({
                pathname: `${process.env.PUBLIC_URL}/otp`,
                state: response?.data,
            });
        } else if (
            response.data.userId == 1 ||
            checkUserRole() ||
            response.data.userRoleCode === "RL_NATION" ||
            response.data.userRoleCode === "RL_LOCAL" ||
            response.data.userRoleCode === "RL_DISTRICT"
        ) {
            history.push(`${process.env.PUBLIC_URL}/dashboard/main`);
        } else if (
            response.data.schoolId === "" ||
            response.data.schoolId === null ||
            response.data.schoolId === undefined
        ) {
            if (
                response.data.userRoleCode === "RL_STD" ||
                response.data.userRoleCode === "RL_OTH"
            ) {
                history.push(`${process.env.PUBLIC_URL}/student`);
            } else if (response.data.userRoleCode === "RL_STF") {
                history.push(`${process.env.PUBLIC_URL}/teacher`);
            } else if (response.data.userRoleCode === "RL_STC") {
                history.push(
                    `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
                );
            }
        } else if (response.data.userRoleCode === "RL_STC") {
            history.push(
                `${process.env.PUBLIC_URL}/familyPage/${response.data.familyId}`
            );
        } else if (
            response.data.schoolId &&
            (response.data.userRoleCode === "RL_STD" ||
                response.data.userRoleCode === "RL_OTH")
        ) {
            response.data.schoolSetup
                ? response.data.academicYears
                    ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                    : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
        } else if (
            response.data.schoolId &&
            response.data.userRoleCode === "RL_STC"
        ) {
            response.data.schoolSetup
                ? response.data.academicYears
                    ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                    : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
        } else {
            response.data.schoolSetup
                ? response.data.academicYears
                    ? history.push(`${process.env.PUBLIC_URL}/profile/`)
                    : history.push(`${process.env.PUBLIC_URL}/academicYears`)
                : history.push(`${process.env.PUBLIC_URL}/systemSetup`);
        }
    };

    asyncSchoolChange = (val) => {
        return new Promise((resolve, reject) => {
            axiosPost(
                URL.getSchoolByCode,
                { name: val, isAllowed: true },
                (response) => {
                    if (response.status === 200) {
                        const schools = response.data.data;
                        schools.forEach((s) => {
                            s.label = s.name;
                            s.value = s.code;
                        });
                        resolve(schools);
                    } else {
                        reject();
                    }
                },
                (err) => {
                    reject(err);
                },
                true
            );
        });
    };

    handleSchoolChange = (selected) => {
        if (selected && selected.hasInstance) {
            this.setState({ selectedSchool: selected });
        } else if (selected == null) {
            this.setState({ selectedSchool: selected });
        } else {
            swal(
                "Warning",
                "System is not available for this school. Please contact your school."
            );
            return;
        }
    };

    //forgot password

    handleCheckboxA = (e) => {
        const { name, checked } = e.target;
        this.setState({ [name]: checked, viaAdmin: false });
    };

    handleCheckboxB = (e) => {
        const { name, checked } = e.target;
        this.setState({ [name]: checked, viaEmail: false });
    };

    handleDataChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
    };

    handleForgetPassword = (e) => {
        e.preventDefault();
        let params = {
            username: this.state.forgotUsername,
            userRoleCode: this.state.forgotUserType,
            link: this.state?.viaAdmin ? "" : `${reactServer}/resetPassword/:userId`,
        };

        //url must be different for different password reset options for user

        let url = "";
        if (this.state.viaAdmin) {
            url = URL.forgetPassword;
        } else {
            url = URL.resetViaEmail;
        }

        this.setState(
            {
                showSpinner: true,
            },
            () => {
                axiosPost(
                    url,
                    params,
                    (response) => {
                        this.setState({ showSpinner: false });
                        if (response.status === 200) {
                            // setTimeout(() => {
                            //   history.push(`${process.env.PUBLIC_URL}/`);
                            // }, 5000);
                            displaySuccessAlert(response);
                            this.setState({
                                modal: !this.state.modal,
                                forgotUsername: "",
                                forgotUserType: "",
                            });
                        }
                    },
                    (err) => {
                        this.setState({ showSpinner: false });
                        displayErrorMessage(err);
                    }
                );
            }
        );
    };

    forgotPasswordModal = () => {
        this.setState({ modal: !this.state.modal });
    };

    forgotModalBody = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-5">
                        <strong>Username </strong>
                    </div>
                    <div className="col-md-7">
                        <input
                            name="forgotUsername"
                            type="text"
                            className="form-control"
                            value={this.state.forgotUsername}
                            placeholder="Type your Username"
                            onChange={this.handleDataChange}
                        ></input>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-md-5">
                        <strong>User Type</strong>
                    </div>
                    <div className="col-md-7">
                        <select
                            className="form-control"
                            name="forgotUserType"
                            value={this.state.forgotUserType}
                            onChange={this.handleDataChange}
                        >
                            <option value="" disabled>
                                Choose user type
                            </option>
                            {this.state.userType.map((type, idx) => {
                                return (
                                    <option key={idx} value={type.code}>
                                        {type.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div className="row mt-2 mb-2">
                    <div className="col-md-5">
                        <strong>Reset Password Option</strong>
                    </div>
                    <div className="col-md-7">
                        <div>
                            <label className="">Via Email</label>
                            <input
                                style={{ marginLeft: "10px" }}
                                name="viaEmail"
                                type="checkbox"
                                checked={this.state?.viaEmail}
                                onChange={(e) => this.handleCheckboxA(e)}
                                className="tt-checkbox"
                            // disabled={this.state?.viaAdmin}
                            />
                        </div>
                        <div>
                            <label className="">Via Admin</label>
                            <input
                                style={{ marginLeft: "10px" }}
                                name="viaAdmin"
                                type="checkbox"
                                checked={this.state?.viaAdmin}
                                onChange={(e) => this.handleCheckboxB(e)}
                                className="tt-checkbox"
                            // disabled={this.state?.viaEmail}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    forgotModalFooter = () => {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-right">
                        <button
                            className="cusBtn cusBtnFontSize mr-3"
                            onClick={this.handleForgetPassword}
                        >
                            Submit
                        </button>
                        <button
                            className="cusBtn cusBtnFontSize"
                            onClick={this.forgotPasswordModal}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    handleTogglePassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword
        }));
    };

    redirectSchoolLogin = () => {
        this.props.history.push('/school-login')
    }

    render() {
        return (
            <div className="container-fluid px-0">
                {this.state.showSpinner ? (
                    <CustomSpinner text="Please wait...."></CustomSpinner>
                ) : null}

                <div className="loginDiv">
                    <div className="row newLoginBgdiv">
                        <div style={{ margin: "inherit" }} className="col-md-12 d-flex align-items-center justify-content-end py-3" onClick={this.redirectSchoolLogin}>
                            <span className="signInChanger">School Sign In ?</span>
                        </div>

                        <div className="col-md-12 loginBoxDiv">
                            <div className="loginBoxHolder">
                                <div className="d-flex flex-column">
                                    <img
                                        src={imagineLogo}
                                        style={{ height: "80px", width: "auto" }}
                                        onError={(e) => (e.target.src = imagineLogo)}
                                        alt="school-logo"
                                    />
                                    <div className="bottomText" id="click">
                                        Augment Your Learning
                                    </div>
                                </div>

                                <div className="login-toggler mt-2">
                                    <div>
                                        <form onSubmit={e => this.handleInstitutionLogin(e)}>
                                            <div>
                                                <div className="position-relative mb-2" style={{ visibility: "hidden", pointerEvents: "none" }}>
                                                    <span
                                                        style={{ visibility: "hidden", pointerEvents: "none" }}
                                                        className="material-icons loginReactIcon mt-2"
                                                        aria-hidden="true"
                                                    >
                                                        school
                                                    </span>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        loadOptions={this.asyncSchoolChange}
                                                        value={this.state.selectedSchool}
                                                        onChange={sel => this.handleSchoolChange(sel)}
                                                        // className="inputColor"
                                                        isClearable={true}
                                                        placeholder="Type your School Code"
                                                        classNamePrefix="loginReactSelect"
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                visibility: "hidden",
                                                                pointerEvents: "none",
                                                                '&:focus': {
                                                                    visibility: "hidden",
                                                                pointerEvents: "none",
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="position-relative mb-2">
                                                    <span
                                                        className="material-icons-round loginIcon mt-2"
                                                        aria-hidden="true"
                                                    >
                                                        person_outline
                                                    </span>

                                                    <input
                                                        style={{ border: "1px solid #126696", backgroundColor: "white" }}
                                                        type="text"
                                                        name="username"
                                                        className="customTextInput mt-2"
                                                        value={this.state.username}
                                                        onChange={this.handleChange}
                                                        placeholder="Username"
                                                    ></input>
                                                </div>
                                                <div className="position-relative">
                                                    <span className="material-icons-round loginIconPsw">
                                                        key
                                                    </span>
                                                    <input
                                                        style={{ border: "1px solid #126696", backgroundColor: "white" }}
                                                        type={this.state.showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        className="customTextInput mt-2"
                                                        value={this.state.password}
                                                        onChange={this.handleChange}
                                                        placeholder="Password"
                                                    ></input>
                                                    <span className="forgotPasswordEye" onClick={this.handleTogglePassword}>
                                                        <FontAwesomeIcon
                                                            icon={this.state.showPassword ? faEyeSlash : faEye}
                                                        />
                                                    </span>
                                                </div>

                                                <div className="text-center mt-3">
                                                    <p
                                                        className="tt-forgot-password forgotPsw-hover mr-auto"
                                                        onClick={this.forgotPasswordModal}
                                                    >
                                                        Forgot Password?
                                                    </p>
                                                    <CustomButton
                                                        text="Sign In"
                                                        type="submit"
                                                        customStyle="loginBtn mt-0"
                                                        onClick={
                                                            this.state.activeTab === "1"
                                                                ? this.handleLogin
                                                                : this.handleInstitutionLogin
                                                        }
                                                    ></CustomButton>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalWindow
                    modal={this.state.modal}
                    modalClass="forgotModal"
                    modalHeader="Request New Password"
                    modalBody={this.forgotModalBody()}
                    modalFooter={this.forgotModalFooter()}
                ></ModalWindow>
            </div>
        );
    }
}

export default withRouter(LoginPage);
