import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosGet } from "../../utils/AxiosApi";
import NavBar from "../FrontPage/NavBar";
import "../../assets/scss/calculator.scss";
import CustomPageHeader from "../common/CustomPageHeader";
import { connect } from "react-redux";

class Dictionary extends Component {
  state = {
    apiURL: "https://api.dictionaryapi.dev/api/v2/entries/en/",
    key: null,
    meaning: null,
    error: false,
    loading: false,
  };

  callDictionaryApi = () => {
    if (this.state.apiURL && this.state.key && this.state.key !== "") {
      this.setState({ error: null, meaning: null, loading: true }, () => {
        axiosGet(
          `${this.state.apiURL}${this.state.key}`,
          (response) => {
            if (response.data && response.data[0]) {
              this.setState({
                meaning: response.data[0],
                loading: false,
                error: false,
              });
            } else {
              this.setState({
                meaning: response.data[0],
                loading: false,
                error: false,
              });
            }
          },
          (err) => {
            this.setState({ meaning: null, loading: false, error: true });
          }
        );
      });
    }
  };

  renderAudioSource = (meaning) => {
    const audio = meaning?.phonetics?.find((p) => p.audio && p.audio != "");
    return audio ? audio.audio : null;
  };

  render() {
    const { meaning } = this.state;
    // const { pathname } = this?.props?.location;

    return (
      <>
      <div className="tt-widgetContent-tab-holder" style={{height:"84.5vh"}}>
        {/* {pathname === "/dashboard/dictionary" ? null : ( */}
          {/* <NavBar
            noNav={true}
            teacherPage={this.props?.location?.state?.teacherPage}
            classPage={this.props?.location?.state?.classPage}
            homePage={
              this.props?.location?.state?.homePage || this.props?.homePage
            }
          /> */}
        {/* )} */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="whiteContainer mt-5">
                  {/* <CustomPageHeader
                    title={
                      this.props?.lang === "nameEn" ? "Dictionary" : "शब्दकोश"
                    }
                    noIcon={true}
                  /> */}
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        name="key"
                        className="form-control"
                        placeholder="Type your word"
                        onChange={(e) => this.setState({ key: e.target.value })}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.callDictionaryApi();
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-2 mt-1">
                      <button
                        className="btn btn-primary cusBtn cusBtnFontSize"
                        onClick={this.callDictionaryApi}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                  {!this.state.loading ? (
                    <>
                      {meaning && (
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col">
                                {/* <h1 className="text-success text-center mb-3">
                                  {meaning?.word}
                                </h1> */}
                                {meaning?.phonetic && (
                                  <span style={{ fontSize: "1.1rem" }}>
                                    <strong
                                      className="primaryColor"
                                      style={{ fontSize: "1.3rem" }}
                                    >
                                      Phonetic:
                                    </strong>{" "}
                                    {meaning?.phonetic}
                                  </span>
                                )}
                              </div>
                            </div>
                            {this.renderAudioSource(meaning) && (
                              <div className="row mb-2">
                                <div className="col">
                                  <h2>
                                    <figure className="mb-0">
                                      <figcaption
                                        className="primaryColor"
                                        style={{
                                          fontSize: "1.3rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Pronunciation:
                                      </figcaption>
                                      <audio
                                        controls
                                        src={this.renderAudioSource(meaning)}
                                      >
                                        {/* <a href={this.renderAudioSource(meaning)}>
                                Download audio
                              </a> */}
                                      </audio>
                                    </figure>
                                  </h2>
                                </div>
                              </div>
                            )}

                            <div className="row">
                              <div className="col">
                                {meaning?.meanings.map((mm) => (
                                  <div className="row mb-4">
                                    <div className="col">
                                      <div className="d-flex">
                                        <h6>
                                          <strong className="primaryColor">
                                            Part of Speech:{" "}
                                          </strong>
                                        </h6>
                                        <h6>&nbsp;{mm?.partOfSpeech}</h6>
                                      </div>
                                      <table className="table cusTable">
                                        <tbody>
                                          {mm?.definitions?.map((d, didx) => (
                                            <tr key={didx}>
                                              <td width="110px">
                                                <span
                                                  style={{ fontSize: "1rem" }}
                                                >
                                                  {++didx}. Definition
                                                </span>
                                              </td>
                                              <td>
                                                <span
                                                  style={{ fontSize: "1rem" }}
                                                >
                                                  {d.definition}
                                                </span>
                                                <br />
                                                {d.example &&
                                                d.example !== "" ? (
                                                  <small
                                                    style={{
                                                      fontStyle: "italic",
                                                    }}
                                                  >
                                                    Eg: {d.example}
                                                  </small>
                                                ) : null}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    {mm?.synonyms?.length > 0 ||
                                    mm?.antonyms?.length > 0 ? (
                                      <div className="col-md-3">
                                        <div className="synAnt">
                                          {mm?.synonyms?.length > 0 && (
                                            <>
                                              <strong>Synonym: </strong>
                                              <br></br>
                                              {mm?.synonyms?.map(
                                                (ds, dsidx, arr) => (
                                                  <span>
                                                    {ds}
                                                    {dsidx != arr.length - 1
                                                      ? `, `
                                                      : ``}
                                                  </span>
                                                )
                                              )}
                                              <br></br>
                                            </>
                                          )}
                                          <div className="mt-3">
                                            {mm?.antonyms?.length > 0 && (
                                              <>
                                                <strong>Antonym:</strong>
                                                <br></br>
                                                {mm?.antonyms?.map(
                                                  (dm, dmidx, arr) => (
                                                    <span>
                                                      {dm}
                                                      {dmidx != arr.length - 1
                                                        ? ", "
                                                        : ""}
                                                    </span>
                                                  )
                                                )}
                                                <br></br>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="text-center p-4">
                      <Spinner />
                    </div>
                  )}
                  <div>
                    {this.state.error && (
                      <h4 className="text-center mt-3">
                        No meaning found. Please check your spelling.
                      </h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lang: state.languageVal.lang,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Dictionary);
