import React, { Component } from "react";
import { userRole, userRoleCode } from "../../utils/Constants";

class CourseSyllabusDetailReRender extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.topicList && this.props.topicList.length > 0
          ? this.props.topicList.map((topic, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className="row" key={idx} style={{ fontSize: "14px" }}>
                    <div className="col">
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <span
                          className="backGround"
                          style={{ paddingLeft: this.props.index * 3 + "0px" }}
                        >
                          {idx + 1}. {topic.name}
                        </span>
                        <span
                          style={{ height: "inherit", width: "5px" }}
                          className="backGround"
                        ></span>
                        <div className="dotsRender"></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col text-center">
                          {topic.lectureHour}
                        </div>
                        <div className="col text-center">
                          {topic.practicalHour}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      {!this.props.fromArr && (userRole.toString() !== userRoleCode.roleStudent &&
                        userRole.toString() !== userRoleCode.roleStudentContact) && (
                          <>
                            <button
                              className="tt-button tt-button-primary mr-2"
                              onClick={() =>
                                this.props.handleUpdateSyllabusDetail(topic)
                              }
                            >
                              Edit
                            </button>
                            <button
                              className="tt-button tt-button-primary mr-2"
                              onClick={() =>
                                this.props.handleSyllabusDetailDelete(topic.id)
                              }
                            >
                              Delete
                            </button>
                          </>
                        )}
                      <button
                        className="tt-button tt-button-primary"
                        onClick={() =>
                          this.props.toggleSyllabusResourcesModalView(topic)
                        }
                      >
                        View Resource
                      </button>

                      {!this.props.fromArr && (userRole.toString() !== userRoleCode.roleStudent &&
                        userRole.toString() !== userRoleCode.roleStudentContact) && (
                          <button
                            className="tt-button tt-button-primary"
                            onClick={() =>
                              this.props.syllabusResourceModal(topic)
                            }
                          >
                            Add Resources
                          </button>
                        )}
                    </div>
                  </div>
                  {topic.subTopic && topic.subTopic.length > 0 ? (
                    <CourseSyllabusDetailReRender
                      topicList={topic.subTopic}
                      index={this.props.index + 1}
                      handleUpdateSyllabusDetail={
                        this.props.handleUpdateSyllabusDetail
                      }
                      handleSyllabusDetailDelete={
                        this.props.handleSyllabusDetailDelete
                      }
                      syllabusResourceModal={this.props.syllabusResourceModal}
                      fromArr={this.props.fromArr}
                    ></CourseSyllabusDetailReRender>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
      </>
    );
  }
}

export default CourseSyllabusDetailReRender;
